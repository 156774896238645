import packageJson from '../../../package.json';

const state = {
  appTitle: 'Solfacil',
  appVersion: packageJson.version,
};

const actions = {
  setAppVersion({ commit }, payload) {
    state.appVersion = payload;
    commit('SET_APP_VERSION');
  },
};

const mutations = {
  SET_APP_VERSION(state, version) {
    state.appVersion = version;
  },
};

const getters = {
  appTitle: (state) => state.appTitle,
  appVersion: (state) => state.appVersion,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
