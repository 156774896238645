window.onload = async () => {
  if (typeof window.fbq === 'function') {
    await window.fbq('init', process.env.VUE_APP_PIXEL_INIT);
  }
};

export default {
  install(Vue) {
    Vue.prototype.trackingPixel = (event) => {
      if (typeof window.fbq === 'function') return window.fbq('track', event);
      return;
    };
  },
};
