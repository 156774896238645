import brasil from '@/data/brasil.json';

export default {
  mounted() {
    if (this.address?.state) {
      this.cities = this.getCitiesByState(this.address.state);
    }
  },

  watch: {
    'form.address.state'(value) {
      this.cities = this.getCitiesByState(value);
    },
  },

  methods: {
    getCitiesByState(state) {
      return brasil[state]?.cidades.map((city) => {
        return {
          value: city,
          name: city,
        };
      });
    },
  },
};
