<template>
  <div class="footer">
    <hr />
    <p>
      Solfacil Energia Solar Tecnologia e Serviços Financeiros Ltda - CNPJ:
      31.931.053/0001-50 <br />
      Rua Cardeal Arcoverde, 2.450, 5° andar, cjs. 501 a 511, Pinheiros, São
      Paulo/SP, CEP: 05.408-003.
    </p>
    <p class="mt-6">
      <strong class="text-modest">
        <a href="https://loja.solfacil.com.br/termos" target="_blank"
          >Termos e Condições da Loja Solfácil</a
        > </strong
      >.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>
<style lang="scss" scoped>
.footer {
  hr {
    @apply my-6 mb-6;
  }
  p {
    @apply text-slight text-grayPrimary;
  }
}
</style>
