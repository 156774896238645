<template>
  <div class="sol-range">
    <p v-if="title">{{ title }}</p>

    <vue-slider
      v-model="val"
      :dot-size="16"
      :height="8"
      :interval="1.0"
      :min="min"
      :max="max"
      :tooltip="'none'"
      :contained="true"
      @change="change"
    />

    <div class="interval">
      <Input
        id="minKwp"
        :value="convertToKwp(val[0])"
        :invalid="Boolean(minValueError)"
        :msg-invalid="minValueError"
        type="number"
        no-arrows
        @blur="handleInputMin"
      />
      <Input
        id="maxKwp"
        :value="convertToKwp(val[1])"
        :invalid="Boolean(maxValueError)"
        :msg-invalid="maxValueError"
        type="number"
        no-arrows
        @blur="handleInputMax"
      />
    </div>
  </div>
</template>

<script>
import { Input } from '@solfacil/components-ui';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'SliderRange',

  components: {
    Input,
    VueSlider,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    title: {
      type: String,
      default: null,
    },

    min: {
      type: Number,
      require: true,
      default: 0,
    },

    max: {
      type: Number,
      require: true,
      default: 0,
    },
  },

  data() {
    return {
      val: null,
      minValueError: '',
      maxValueError: '',
    };
  },

  watch: {
    value(newVal) {
      this.val = newVal.map((i) => Number(i));
    },
  },

  created() {
    this.val = this.value.map((i) => Number(i));
  },

  methods: {
    change(val) {
      this.val = val.map((i) => Number(i));
      this.updateInputs(val);
      this.$emit('input', val);
    },

    convertToKwp(val, mult = false) {
      if (mult) return val * 1000;
      return (val / 1000).toFixed(2);
    },

    updateInputs(val) {
      this.handleInputMin(this.convertToKwp(val[0]));
      this.handleInputMax(this.convertToKwp(val[1]));
    },

    handleInputMin(value) {
      if (
        this.convertToKwp(value, true) < this.min ||
        this.convertToKwp(value, true) > this.val[1]
      )
        this.minValueError = 'Valor Inválido';
      else {
        this.val[0] = this.convertToKwp(value, true);
        this.minValueError = '';
      }

      this.$emit('input', this.val);
    },

    handleInputMax(value) {
      if (
        this.convertToKwp(value, true) > this.max ||
        this.convertToKwp(value, true) < this.val[0]
      )
        this.maxValueError = 'Valor Inválido';
      else {
        this.val[1] = this.convertToKwp(value, true);
        this.maxValueError = '';
      }

      this.$emit('input', this.val);
    },
  },
};
</script>

<style lang="scss">
.sol-range {
  p {
    @apply font-rubik text-grayPrimary text-small leading-tight mb-4;
  }

  .vue-slider {
    @apply py-1 relative #{!important};

    &::before {
      @apply bg-gray2 h-2 w-full absolute left-0 rounded-full;
      content: '';
      top: 4px;
    }

    .vue-slider-rail {
      @apply bg-gray2;
    }

    .vue-slider-process,
    .vue-slider-dot-handle {
      @apply bg-rebrandingPrimary shadow-none;
    }
  }

  .interval {
    @apply flex justify-between mt-2;

    input {
      @apply w-13;
    }
  }
}
</style>
