/**
 * loader global
 */
const state = () => ({
  count: 1,
});
const actions = {
  showLoad({ commit }) {
    commit('START_LOADING');
  },
  hideLoad({ commit }) {
    setTimeout(() => {
      commit('FINISH_LOADING');
    }, 500);
  },
};
const mutations = {
  START_LOADING: (state) => state.count++,
  FINISH_LOADING: (state) => {
    if (state.count >= 1) {
      state.count--;
    }
  },
};

const getters = {
  showLoading: (state) => state.count,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
