<template>
  <div class="box-order">
    <SelectInput
      v-model="orderSelected"
      id="order"
      :hasScroll="false"
      class="flex items-center gap-2 select-order"
      :searchable="false"
      :options="orderOptions"
      :placeholder="placeholderOrder"
      label="Ver primeiro por:"
      @change="emitOrderEvent"
    />
  </div>
</template>

<script>
import { SelectInput } from '@solfacil/components-ui';
export default {
  name: 'DropdownOrder',
  components: {
    SelectInput,
  },
  props: {
    orderOptions: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      orderSelected: 'priority',
    };
  },

  methods: {
    emitOrderEvent() {
      const orderFiltered = this.orderOptions.filter(
        (order) => order.value === this.orderSelected,
      );

      this.$emit('onSelectChange', orderFiltered);
    },
  },

  computed: {
    getLocalStorageOrder() {
      const {
        marketplace: { sortBy, sortOrder },
      } = JSON.parse(localStorage.getItem('mkt'));
      return {
        sortBy,
        sortOrder,
      };
    },

    placeholderOrder() {
      const placeholder = this.orderOptions.find(
        (order) =>
          order.key === this.getLocalStorageOrder.sortBy &&
          order.sort === this.getLocalStorageOrder.sortOrder,
      );
      return placeholder?.name ?? 'Recomendado';
    },
  },

  created() {
    if (Object.keys(this.getLocalStorageOrder).length) {
      this.orderSelected = this.getLocalStorageOrder.sortBy;
    }
  },
};
</script>

<style lang="scss" scoped>
.box-order {
  @apply flex justify-end items-center;
  .select-order {
    ::v-deep .select-main {
      width: 160px;
      .placeholder {
        @apply text-sm text-grayPrimary;
      }
    }
  }
  ::v-deep .options-container {
    padding: 2px;
    width: 200px;
    height: 285px;
    transform: translateX(75px);
  }
}
</style>
