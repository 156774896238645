<template>
  <span
    class="icon-hamburger"
    :class="{ open: statusHamburger }"
    @click="toggleHamburger"
  >
    <span />
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'IconHamburger',

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('hamburger', ['statusHamburger']),
  },

  methods: {
    ...mapActions('hamburger', ['toggleHamburger']),
  },
};
</script>

<style lang="scss" scoped>
.icon-hamburger {
  @apply cursor-pointer flex flex-col w-6 h-6 items-center justify-center relative;

  &::after,
  &::before {
    @apply w-2 rounded bg-grayPrimary absolute duration-200 ease-linear transition-all;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
  }

  &::after {
    bottom: 5px;
  }

  &::before {
    top: 5px;
  }

  span {
    @apply w-4 rounded bg-grayPrimary;
    height: 2px;
  }

  &.open {
    &:after,
    &::before {
      @apply w-4;
    }

    &:after {
      transform: translateX(-50%) rotate(45deg);
      bottom: 11px;
    }

    &:before {
      transform: translateX(-50%) rotate(-45deg);
      top: 11px;
    }

    span {
      @apply hidden;
    }
  }

  @screen lg {
    @apply hidden;
  }
}
</style>
