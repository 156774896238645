<template>
  <div
    class="HeadlinePage"
    :class="[
      {
        'HeadlinePage--disabled': title === 'Meus pedidos',
        'HeadlinePage--active': shipping,
      },
    ]"
  >
    <div class="flex items-center gap-4">
      <imgBack
        v-if="backpage"
        alt="Voltar"
        class="cursor-pointer"
        @click="back"
      />

      <h1>{{ title }}</h1>
    </div>

    <Badge
      v-if="shipping"
      id="done-1"
      variant="done"
      class="ml-auto mr-4 lg:mr-0"
    >
      Frete incluso
    </Badge>

    <IconHamburger id="ico-hamburger" />

    <hr />
  </div>
</template>

<script>
import { Badge } from '@solfacil/components-ui';
import { IconHamburger } from '@components';
import imgBack from '@img/icons/icon-go-back-small.svg';

export default {
  name: 'HeadlinePage',

  components: {
    Badge,
    IconHamburger,
    imgBack,
  },

  props: {
    title: {
      required: true,
      type: String,
    },

    backpage: {
      type: Boolean,
      default: false,
    },

    shipping: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.HeadlinePage {
  @apply flex items-center justify-between;

  &--active {
    @apply flex flex-wrap items-center justify-between;
  }

  &--disabled {
    @apply flex-wrap items-center justify-between;
  }

  h1 {
    @apply text-moderate leading-tight;
  }

  hr {
    @apply w-full my-4;
  }

  @screen lg {
    h1 {
      @apply text-large leading-none;
    }

    hr {
      @apply mt-6 mb-4;
    }
  }
}
</style>
