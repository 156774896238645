<template>
  <Modal
    id="modal-details-status-order"
    title="Pedido em trânsito - Detalhes"
    :value="isOpenModal"
    button-close="primary"
    @closeModal="$emit('closeModal')"
  >
    <SortedTable
      id="list-status-order"
      :fields="titleTable"
      class="list-order"
      order-by="asc"
    >
      <template #tbody>
        <tr v-for="(item, index) in trackingOrder" :key="index" class="row">
          <td role="cell">
            <span>Pedido {{ item.macro_status }} </span>
          </td>
          <td role="cell">
            <span>{{ item.description }} </span>
          </td>
        </tr>
      </template>
    </SortedTable>
  </Modal>
</template>
<script>
import { Modal, SortedTable } from '@solfacil/components-ui';
export default {
  name: 'ModalDetailsTrackingOrder',
  components: {
    Modal,
    SortedTable,
  },
  props: {
    isOpenModal: {
      type: Boolean,
      default: false,
    },
    trackingOrder: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      titleTable: [
        { key: 'status', title: 'Status' },
        { key: 'descricao', title: 'Descrição' },
      ],
    };
  },
};
</script>
