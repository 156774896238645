const TOKEN_KEY = 'access_token';
const USER = 'user';

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  getUser() {
    const user = localStorage.getItem('user');

    return JSON.parse(user);
  },

  saveUser(user) {
    localStorage.setItem(USER, user);
  },

  removeStorage() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER);
  },
};

export { TokenService };
