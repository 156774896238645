<template>
  <a
    :id="id"
    class="float-whats"
    :href="whatsAppLink"
    title="Atendimento via WhatsApp"
    rel="noopener noreferrer"
    target="_blank"
  >
    <img
      src="@img/icons/icon-whatsapp.svg?inline"
      alt="Atendimento via WhatsApp"
    />
  </a>
</template>

<script>
export default {
  name: 'WhatsApp',

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    whatsAppLink: `https://wa.me/${process.env.VUE_APP_WHATSAPP_NUMBER}`,
  }),
};
</script>

<style lang="scss" scoped>
.float-whats {
  @apply fixed z-50 cursor-pointer w-8 h-8 block;
  top: 80%;
  right: 16px;
  bottom: 16px;

  @screen lg {
    @apply w-10 h-10;
    right: 24px;
    bottom: 24px;
  }
}
</style>
