export const VIA_CEP = 'https://viacep.com.br/ws/';

export const ZIP_CODE_NOT_FOUND =
  'CEP inválido, verifique e tente novamente. Caso problema persista, entre em contato conosco: (11) 952456804';

export const ERROR_MESSAGES_OPEN_MODAL = [
  'o kit selecionado sofreu uma alteração de preço.',
  'ocorreu uma mudança no frete do produto',
  'out of stock',
];

export const TITLE_TABLE = [
  { key: 'price', title: 'Preço', sortable: true },
  { key: 'module_power', title: 'Módulos', sortable: true },
  { key: 'inverter_power', title: 'Inversor', sortable: true },
  { key: 'roof_type', title: 'Tipo de telhado' },
  { key: 'shipping_date_limit', title: 'Prazo de Entrega', sortable: true },
  { key: 'amount', title: 'Qtde' },
];

export const ORDER_OPTIONS = [
  { key: 'priority', value: 'priority', name: 'Recomendado', sort: 'asc' },
  { key: 'price', value: 'min_price', name: 'Menor Preço', sort: 'asc' },
  { key: 'price', value: 'max_price', name: 'Maior Preço', sort: 'desc' },
  {
    key: 'shipping_date_limit',
    value: 'min_shipping',
    name: 'Menor prazo de entrega',
    sort: 'asc',
  },
  {
    key: 'shipping_date_limit',
    value: 'max_shipping',
    name: 'Maior prazo de entrega',
    sort: 'desc',
  },
  {
    key: 'module_power',
    value: 'min_potency',
    name: 'Menor potência do kit',
    sort: 'asc',
  },
  {
    key: 'module_power',
    value: 'max_potency',
    name: 'Maior potência do kit',
    sort: 'desc',
  },
];

export const STATES = [
  { value: 'AC', name: 'Acre' },
  { value: 'AL', name: 'Alagoas' },
  { value: 'AP', name: 'Amapá' },
  { value: 'AM', name: 'Amazonas' },
  { value: 'BA', name: 'Bahia' },
  { value: 'CE', name: 'Ceará' },
  { value: 'DF', name: 'Distrito Federal' },
  { value: 'ES', name: 'Espírito Santo' },
  { value: 'GO', name: 'Goiás' },
  { value: 'MA', name: 'Maranhão' },
  { value: 'MT', name: 'Mato Grosso' },
  { value: 'MS', name: 'Mato Grosso do Sul' },
  { value: 'MG', name: 'Minas Gerais' },
  { value: 'PA', name: 'Pará' },
  { value: 'PB', name: 'Paraíba' },
  { value: 'PR', name: 'Paraná' },
  { value: 'PE', name: 'Pernambuco' },
  { value: 'PI', name: 'Piauí' },
  { value: 'RJ', name: 'Rio de Janeiro' },
  { value: 'RN', name: 'Rio Grande do Norte' },
  { value: 'RS', name: 'Rio Grande do Sul' },
  { value: 'RO', name: 'Rondônia' },
  { value: 'RR', name: 'Roraima' },
  { value: 'SC', name: 'Santa Catarina' },
  { value: 'SP', name: 'São Paulo' },
  { value: 'SE', name: 'Sergipe' },
  { value: 'TO', name: 'Tocantins' },
];
