/**
 * import and init global plugins
 */
import Vue from 'vue';
import globalEventBus from '@/plugins/globalEventBus';
import apiServer from '@/plugins/apiServer.js';
import sentry from '@/plugins/sentry.js';
import trackingEvent from '@/plugins/trackingEvent';
import launchdarkly from '@/plugins/Launchdarkly';
import trackingPixel from '@/plugins/trackingPixel';

Vue.use(globalEventBus);
Vue.use(apiServer);
Vue.use(sentry);
Vue.use(trackingEvent);
Vue.use(trackingPixel);
Vue.use(launchdarkly);
