export default [
  {
    path: '/',
    redirect: { name: 'home' },
    component: () => import('@/views/Cart.vue'),
    children: [
      {
        path: '/loja/carrinho',
        name: 'cart',
        meta: {
          title: 'Carrinho',
        },
      },
    ],
  },
];
