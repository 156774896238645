import { getFormattedProductsPrice } from '@/helper/getFormattedProductsPrice.helper';
import { DataLoader } from '@/services/dataLoader';
import { Notify } from '@/services';

/**
 * check for duplicated keys before the filters query
 * @param {Array} options - array of strings
 * @returns {Boolean} - true if there are duplicated keys
 */
const _checkForDuplicatedFilters = (options) => {
  // transform the options array of strings in a array of objects
  const optionsArr = options.map((item) => {
    const [key, value] = item.split(':').map((value) => value.trim());

    return { [key]: value };
  });

  // get all keys inside the new optionsArr to check for duplicates
  const optionsKeys = optionsArr.map((option) => Object.keys(option)[0]);

  // confront the optionsKeys with the optionsArr to check for duplicates and returns the result
  return optionsKeys.some((item, index) => optionsArr.indexOf(item) !== index);
};

/**
 * remove duplicated keys before the filters query
 * @param {Array} options - array of strings
 * @returns {Array} - array of strings
 */
const _removeDuplicatedFilters = (options) => {
  // transform the options array of strings in a array of objects with no duplicated keys
  const noDuplicates = Object.fromEntries(
    options.map((item) => item.split(':')),
  );

  // returns an array of strings with no duplicated keys
  return Object.entries(noDuplicates).map(([key, value]) => `${key}:${value}`);
};

/**
 * remove all filters with invalid values
 * @param {Array} options - array of strings with parameters
 * @returns {Array} - array of strings with parameters without invalid values
 */
const _protectFromInvalidValuesOnFilters = (options) => {
  // transform the options array of strings in a array of objects
  const optionsArr = options.map((item) => {
    const [key, value] = item.split(':').map((value) => value.trim());

    return { [key]: value };
  });

  const isValid = (value) =>
    value !== 'null' &&
    value !== null &&
    value !== 'undefined' &&
    value !== undefined &&
    value !== 'NaN';

  // returns an array of strings containing the options without invalid values
  return optionsArr
    .filter((item) => isValid(Object.values(item)[0]))
    .map((item) => {
      const [key, value] = Object.entries(item)[0];

      return `${key}:${value}`;
    });
};

const state = () => ({
  filters: null,
  newFilters: null,
  newFiltersFlag: false,
  filtersSelected: {},
  showMoreFilters: false,
  minCart: null,
  pageSize: 10,
  pagination: null,
  products: null,
  totalCount: null,
  productsCart: [],
  range: {
    min: 0,
    max: 0,
  },
  sort: [],
  sortBy: 'priority',
  sortOrder: 'asc',
  options: [],
  paginationFilter: [],
  toasterOpen: false,
  locationAddress: {
    city: '',
    state: '',
  },
});

const actions = {
  async getProducts({ commit, state, getters }) {
    const { getFormattedLocation } = getters;
    const options = _protectFromInvalidValuesOnFilters(state.options);
    const params = [
      ...options,
      ...state.sort,
      ...state.paginationFilter,
      getFormattedLocation,
    ];

    const payload = {
      query: `
        {
          products(${params}){
            kits: edges {
              node {
                id
                sku
                price
                discounted_price
                applied_promotions {
                    external_id
                    name
                    starts
                    ends
                    accumulative
                }
                shipping{
                    id,
                    cost,
                    min_date_limit,
                    max_date_limit,
                    updated_at
                }
                priceLogCursor
                description
                supplierId
                supplierName
                type
                power
                roofType
                attributes

                kitInverters {
                  amount
                  item {
                    id
                    sku
                    price
                    description
                    supplierId
                    supplierName
                    type
                    power
                    attributes
                  }
                }

                kitModules {
                  amount
                  item {
                    id
                    sku
                    price
                    description
                    supplierId
                    supplierName
                    type
                    power
                    attributes
                  }
                }
              }
              cursor
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
          }
        }
      `,
    };

    try {
      const response = await DataLoader.post(payload);

      if (response.errors) {
        const e = { type: 'error', message: response.errors[0].message };
        Notify(e, this);
        return false;
      }

      const { pageInfo, totalCount } = response.data.products;

      const kits = getFormattedProductsPrice(
        global.structuredClone(response.data.products.kits),
      );

      const pagination = {
        count: totalCount,
        size: state.pageSize,
        afterCursor: pageInfo.hasNextPage ? pageInfo.endCursor : '',
        beforeCursor: pageInfo.hasPreviousPage ? pageInfo.startCursor : '',
      };

      state.productsCart &&
        kits.map((prod) => {
          state.productsCart.map((cart) => {
            if (prod.node.sku === cart.node.sku) {
              prod.node.amount = cart.node.amount;
            }
          });
        });

      commit('SET_PRODUCTS', [...kits]);
      commit('SET_PAGINATION', pagination);
      commit('SET_TOTAL_COUNT', totalCount);

      return true;
    } catch (e) {
      e.type = 'error';
      Notify(e, this);

      return false;
    }
  },

  async getCartUpdate({ getters }, skus) {
    if (!skus.length) return;

    const { getFormattedLocation } = getters;

    const payload = {
      query: `
        {
          products(skus: [${skus}], first: ${skus.length}, ${getFormattedLocation}){
            kits: edges {
              node {
                id
                sku
                price
                discounted_price
                shipping{
                    id,
                    cost,
                    min_date_limit,
                    max_date_limit,
                    updated_at
                }
                applied_promotions {
                    external_id
                    name
                    starts
                    ends
                    accumulative
                }
                priceLogCursor
                description
                supplierId
                supplierName
                type
                power
                roofType
                attributes

                kitInverters {
                  amount
                  item {
                    id
                    sku
                    price
                    description
                    supplierId
                    supplierName
                    type
                    power
                    attributes
                  }
                }

                kitModules {
                  amount
                  item {
                    id
                    sku
                    price
                    description
                    supplierId
                    supplierName
                    type
                    power
                    attributes
                  }
                }
              }
              cursor
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
          }
        }
      `,
    };

    try {
      const response = await DataLoader.post(payload);

      if (response.errors) {
        window.M.toast({
          html: response.errors[0].message,
          displayLength: 12000,
        });
        return false;
      }

      return getFormattedProductsPrice(
        global.structuredClone(response.data.products.kits),
      );
    } catch (e) {
      return false;
    }
  },

  async getFilters({ commit, dispatch, state, getters }) {
    commit('SET_FILTERS', []);

    const payload = {
      query: `
        {
          filters: kitFilters {
            field
            filters
          }
        }
      `,
    };

    try {
      const response = await DataLoader.post(payload);

      if (response.errors) {
        const e = { type: 'error', message: response.errors[0].message };
        Notify(e, this);
        return false;
      }

      const title = {
        power_range: 'Potência (kWp)',
        roof_type: 'Tipo de telhado',
        inverter_brand: 'Marca do inversor',
        kit_own_logistic: 'Solfácil envios',
        delivering_deadline: 'Prazo de entrega',
        module_brand: 'Marca dos módulos',
        inverter_connection: 'Tipo de Ligação',
        inverter_type: 'Tipo de Inversor',
        inverter_power: 'Potência do Inversor',
        kit_unloading: 'Descarregamento',
        kit_insurance_installation: 'Seguro Instalação',
        kit_black_friday: 'Solar Friday',
        supplier: 'Fornecedor',
        module_power: 'Potência dos módulos (Wp)',
        module_amount: 'Quantidade de módulos',
        code: 'Código do Kit',
      };

      const converter = response.data.filters
        .concat({ field: 'code' })
        .map((item) => {
          let minMax = item;

          if (item['field'] === 'power_range') {
            const filterInt = item.filters.map((x) => Number(x));
            const [min, max] = filterInt;

            if (!state.options.length && min && max) {
              commit('SET_OPTIONS', [`minPower: ${min}`, `maxPower: ${max}`]);
            }
            commit('SET_PAGINATION_FILTER', [`first: ${state.pageSize}`]);

            minMax = {
              ...item,
              min,
              max,
            };

            commit('SET_RANGE', { min, max });

            dispatch('getProducts');
          }

          const FILTERS_INTEGER_TYPE = [
            'module_power',
            'module_amount',
            'inverter_power',
          ];

          if (FILTERS_INTEGER_TYPE.includes(item['field'])) {
            const filtersInt = item.filters?.length
              ? item.filters.map((filter) => Number(filter))
              : [];

            minMax = {
              ...item,
              filters: filtersInt,
            };
          }
          return {
            ...minMax,
            searchable: item.filters?.length >= 6,
            title: title[item.field],
            type: getters.getTypeAndLabelFilter(item).type,
            label: getters.getTypeAndLabelFilter(item).label,
          };
        });

      commit('SET_FILTERS', converter);

      return true;
    } catch (e) {
      e.type = 'error';
      Notify(e, this);

      return false;
    }
  },

  async getNewFilters({ commit, state, getters }) {
    commit('SET_NEW_FILTERS', []);

    const { getFormattedLocation, formatFilters } = getters;
    const options = _protectFromInvalidValuesOnFilters(state.options);
    const params = `${options}, ${getFormattedLocation}`;

    const payload = {
      query: `
        {
          filters: dynamic_kit_filters(
            ${params}
          ) {
            field
            items
            type
            disabled
          }
        }
      `,
    };

    try {
      const response = await DataLoader.post(payload);

      if (response.errors) {
        const e = { type: 'error', message: response.errors[0].message };
        Notify(e, this);
        return false;
      }

      commit('SET_NEW_FILTERS', formatFilters(response.data.filters));

      const { min, max } = getters.getPowerRange;

      if (!state.options.length && min && max) {
        commit('SET_RANGE', { min, max });
        commit('SET_OPTIONS', [`minPower: ${min}`, `maxPower: ${max}`]);
      }
      commit('SET_PAGINATION_FILTER', [`first: ${state.pageSize}`]);

      return true;
    } catch (e) {
      e.type = 'error';
      Notify(e, this);

      return false;
    }
  },

  setPageSize({ commit, state }, size) {
    commit('SET_PAGE_SIZE', size);
    commit('SET_PAGINATION_FILTER', [`first: ${state.pageSize}`]);
  },

  async setCart({ commit, state, getters }, value) {
    const cart = getters.getTotalAmount(global.structuredClone(value));

    await commit('SET_MIN_CART', Boolean(cart.amount) && cart);

    await commit('SET_CART', [...value]);

    // Open notify first item cart
    state.productsCart.length === 0 && commit('SET_TOASTER_OPEN', true);
    if (state.productsCart.length === 1 && state.toasterOpen) {
      Notify({ message: 'Kit adicionado ao carrinho' }, this);
      commit('SET_TOASTER_OPEN', false);
    }
  },

  setFiltersSelected({ commit, state }, value) {
    const keyName = {
      kit_min_power: 'minPower',
      kit_max_power: 'maxPower',
      roof_type: 'roofTypes',
      kit_roof_type: 'roofTypes',
      module_amount: 'module_amount',
      module_brand: 'moduleBrands',
      module_power: 'module_power',
      inverter_brand: 'inverterBrands',
      inverter_connection: 'inverterConnections',
      inverter_power: 'inverter_power',
      inverter_type: 'inverterTypes',
      supplier: 'supplierNames',
      delivering_deadline: 'max_date_limit',
      kit_black_friday: 'kitBlackFriday',
      kit_insurance_installation: 'kitInsuranceInstallation',
      kit_own_logistic: 'kitOwnLogistic',
      kit_unloading: 'kitUnloading',
      code: 'code',
    };

    /* - parse the object with the filters into an array of strings to use in the graphql query */
    let options = [];
    Object.keys(value).map(function (key) {
      if (key === 'power_range') {
        if (value?.kit_min_power) delete value.kit_min_power;
        if (value?.kit_max_power) delete value.kit_max_power;

        const [minPower, maxPower] = value[key];

        options.push(
          `minPower: ${Number(minPower)}`,
          `maxPower: ${Number(maxPower)}`,
        );

        return;
      }

      if (key === 'kit_min_power' || key === 'kit_max_power') {
        const power = Math.trunc(Number(value[key]) * 1000);
        options.push(`${keyName[key]}:${JSON.stringify(power)}`);
        return;
      }

      options.push(`${keyName[key]}:${JSON.stringify(value[key])}`);
    });

    if (!value.power_range && !value.kit_min_power && !value.kit_max_power) {
      const { min, max } = state.range;
      options.push(`minPower: ${min}`, `maxPower: ${max}`);
    }

    // check for duplicates inside the array before requesting the query
    const hasDuplicates = _checkForDuplicatedFilters(options);

    // remove duplicates if any
    if (hasDuplicates) options = _removeDuplicatedFilters(options);

    /* - perform the query and set pagination size */
    commit('SET_OPTIONS', options);
    commit('SET_FILTERS_SELECTED', value);
    commit('SET_PAGINATION_FILTER', [`first: ${state.pageSize}`]);
  },

  setSort({ commit }, { sortBy, sortOrder }) {
    const sort = [
      `orderByDirection: "${sortOrder}"`,
      `orderByField: "${sortBy}"`,
    ];
    commit('SET_SORT', { sort, sortBy, sortOrder });
  },

  setPaginationFilter({ commit }, value) {
    commit('SET_PAGINATION_FILTER', value);
  },

  resetOrder({ commit }) {
    commit('RESET');
  },

  resetAddressLocation({ commit }) {
    commit('RESET_ADDRESS_LOCATION');
  },

  setLocationAddress({ commit }, value) {
    commit('SET_LOCATION_ADDRESS', value);
  },

  toggleMoreFiltersView({ commit }) {
    commit('TOGGLE_MORE_FILTERS_VIEW');
  },

  setMoreFiltersViewState({ commit }, value) {
    commit('SET_MORE_FILTERS_VIEW_STATE', value);
  },
};

const mutations = {
  SET_PRODUCTS(state, result) {
    state.products = result;
  },

  SET_FILTERS(state, result) {
    state.filters = result;
  },

  SET_NEW_FILTERS(state, result) {
    state.newFilters = result;
  },

  SET_NEW_FILTERS_FLAG(state, payload) {
    state.newFiltersFlag = payload;
  },

  TOGGLE_MORE_FILTERS_VIEW(state) {
    state.showMoreFilters = !state.showMoreFilters;
  },

  SET_MORE_FILTERS_VIEW_STATE(state, value) {
    state.showMoreFilters = value;
  },

  SET_FILTERS_SELECTED(state, result) {
    state.filtersSelected = result;
  },

  SET_PAGINATION(state, result) {
    state.pagination = result;
  },

  SET_PAGE_SIZE(state, result) {
    state.pageSize = result || 10;
  },

  SET_MIN_CART(state, result) {
    state.minCart = result;
  },

  SET_CART(state, result) {
    state.productsCart = result;
  },

  SET_TOTAL_COUNT(state, result) {
    state.totalCount = result;
  },

  SET_RANGE(state, result) {
    state.range = result;
  },

  SET_OPTIONS(state, result) {
    state.options = result;
  },

  SET_SORT(state, { sort, sortBy, sortOrder }) {
    state.sort = sort;
    state.sortBy = sortBy;
    state.sortOrder = sortOrder;
  },

  SET_PAGINATION_FILTER(state, result) {
    state.paginationFilter = result || 10;
  },

  SET_TOASTER_OPEN(state, result) {
    state.toasterOpen = result;
  },

  SET_LOCATION_ADDRESS(state, result) {
    state.locationAddress = result;
  },

  RESET_ADDRESS_LOCATION(state) {
    state.locationAddress = {
      state: '',
      city: '',
    };
  },

  RESET_SORT_BY(state) {
    state.sortBy = 'priority';
  },

  RESET(state) {
    state.filters = null;
    state.newFilters = null;
    state.filtersSelected = {};
    state.showMoreFilters = false;
    state.minCart = null;
    state.pageSize = 10;
    state.pagination = null;
    state.products = null;
    state.totalCount = null;
    state.productsCart = [];
    state.range = {
      min: 0,
      max: 0,
    };
    state.sort = [];
    state.sortBy = 'priority';
    state.sortOrder = 'asc';
    state.options = [];
    state.paginationFilter = [];
    state.toasterOpen = false;
  },
};

const getters = {
  filters: (state) => state.filters,
  newFilters: (state) => state.newFilters,
  filtersSelected: (state) => state.filtersSelected,
  minCart: (state) => state.minCart,
  pageSize: (state) => state.pageSize,
  pagination: (state) => state.pagination,
  products: (state) => state.products,
  totalCount: (state) => state.totalCount,
  listProductsCart: (state) => state.productsCart,
  range: (state) => state.range,
  sortBy: (state) => state.sortBy,
  sortOrder: (state) => state.sortOrder,
  locationAddress: (state) => state.locationAddress,
  newFiltersFlag: (state) => state.newFiltersFlag,

  getTypeAndLabelFilter: (_, getters) => (item) => {
    const types = {
      power_range: { type: 'range', label: '' },
      kit_unloading: { type: 'switch', label: 'Descarregamento incluso' },
      kit_own_logistic: { type: 'switch', label: 'Solfácil envios' },
      kit_insurance_installation: {
        type: 'switch',
        label: 'Seguro Instalação',
      },
      kit_black_friday: {
        type: 'switch',
        label: 'Solar Friday',
      },
      delivering_deadline: { type: 'radioButton', label: 'Testes' },
      code: { type: 'input', label: 'Código do Kit' },
      default: { type: 'checkbox', label: '' },
    };

    const newTypes = {
      kit_min_power: { type: 'input', label: '' },
      kit_max_power: { type: 'input', label: '' },
      kit_roof_type: { type: 'multiselect', label: 'Tipo de telhado' },
      module_amount: { type: 'multiselect', label: 'Quantidade de módulos' },
      module_brand: { type: 'multiselect', label: 'Marca dos módulos' },
      module_power: { type: 'multiselect', label: 'Potência do Módulo' },
      inverter_brand: { type: 'multiselect', label: 'Marca do inversor' },
      inverter_connection: { type: 'multiselect', label: 'Tipo de Ligação' },
      inverter_power: { type: 'multiselect', label: 'Potência do Inversor' },
      inverter_type: { type: 'multiselect', label: 'Tipo de Inversor' },
      supplier: { type: 'multiselect', label: 'Fornecedor' },
      delivering_deadline: { type: 'selectinput', label: 'Prazo de Entrega' },
      kit_black_friday: { type: 'switch', label: 'Solar Friday' },
      kit_own_logistic: { type: 'switch', label: 'Solfácil envios' },
      kit_unloading: { type: 'switch', label: 'Descarregamento incluso' },
      kit_insurance_installation: {
        type: 'switch',
        label: 'Seguro Instalação',
      },
      code: { type: 'input', label: 'Código do Kit' },
    };

    if (getters.newFiltersFlag) return newTypes[item.field] || types.default;
    return types[item.field] || types.default;
  },

  formatFilters: (_, getters) => (response) => {
    const result = [];

    // Sets the code search position before boolean filters
    const codeFilterLocation =
      response.findIndex(
        (item) => item.field === 'kit_insurance_installation',
      ) || response.length - 1;

    response.splice(codeFilterLocation, 0, { field: 'code' });

    response.forEach((filter, i) => {
      result.push({
        field: filter.field,
        originalField: filter.field,
        filters: [],
        label: getters.getTypeAndLabelFilter(filter).label,
        searchable:
          filter.items && filter.field !== 'delivering_deadline'
            ? filter.items.length >= 6
            : false,
        type: getters.getTypeAndLabelFilter(filter).type,
        disabled: Boolean(filter?.disabled),
      });

      filter.items &&
        filter.items.forEach((val) => {
          const FILTERS_INTEGER_TYPE = [
            'delivering_deadline',
            'inverter_power',
            'module_amount',
            'module_power',
          ];

          const item = {
            value: FILTERS_INTEGER_TYPE.includes(filter.field)
              ? Number(val.value)
              : val.value,
            name:
              filter.field === 'delivering_deadline'
                ? `Até ${val.value} dias úteis`
                : val.value,
            field: filter.field,
            amount: val.amount,
          };

          result[i].filters.push(item);
        });
    });

    return result;
  },

  getPowerRange: (state) => {
    const min = state.newFilters.filter((item) => {
      return item.field.includes('kit_min_power');
    })[0]?.filters[0]?.value;

    const max = state.newFilters.filter((item) => {
      return item.field.includes('kit_max_power');
    })[0]?.filters[0]?.value;

    return { min, max };
  },
  getFormattedLocation: (state) => {
    const { city, state: stateDelivery } = state.locationAddress;

    if (city && stateDelivery) {
      return `city: "${city}", state: "${stateDelivery}"`;
    }

    return `city: "", state: ""`;
  },
  getTotalAmount: () => (value) => {
    return value.reduce(
      (acc, item) => {
        const price = item.node.discounted_price || item.node.price;

        if (item) {
          return {
            amount: acc.amount + item.node.amount,
            total: acc.total + price * item.node.amount,
          };
        }
        return acc;
      },
      {
        amount: 0,
        total: 0,
      },
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
