<template>
  <Modal
    id="modal-confirm-receipt-order"
    title="Confirmação do Pedido"
    sizeLg
    :value="isOpenModal"
    :button-close="isOrderConfirmed ? 'primary' : 'secondary'"
    @closeModal="$emit('closeModal')"
  >
    <div v-if="!isOrderConfirmed" class="container">
      Por favor <span>confirme a data</span> em que seu pedido chegou.
      <span
        ><br />Atenção: verifique se o pedido realmente chegou antes de
        confirmar.</span
      >
      <Input id="input-1" v-model="date" type="date" class="input-date" />
      Em caso de dúvida envie um e-mail para
      <span>solfácil@solfácil.com.br</span>
    </div>

    <div v-else class="flex gap-4">
      <Icon type="success-order" />
      <div class="ml-3">
        <h2>Seu pedido foi confirmado com sucesso!</h2>
        Em caso de dúvida envie um e-mail para: solfácil@solfácil.com.br
      </div>
    </div>
    <template #footer>
      <Button v-if="!isOrderConfirmed" id="rating" @click="confirmOrder">
        Confirmar recebimento do pedido
      </Button>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Input, Modal } from '@solfacil/components-ui';
import { Icon } from '@components/';

export default {
  name: 'ModalConfirmReceiptOrder',
  components: {
    Icon,
    Modal,
    Input,
  },
  props: {
    isOpenModal: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      date: null,
    };
  },

  computed: {
    ...mapGetters('order', ['listNotifications', 'ordersDelivered']),

    isOrderConfirmed() {
      return !this.ordersDelivered.includes(this.order.id);
    },
  },

  methods: {
    ...mapActions('order', ['markNotificationAsRead']),

    async confirmOrder() {
      const { id } = this.listNotifications.find(
        (item) => item.orderId === this.order.id,
      );

      await this.markNotificationAsRead(id, this.date);
    },
  },
};
</script>
<style scoped>
.container {
  @apply text-center;
}
span {
  @apply font-rubik font-medium;
}
.input-date {
  @apply mx-auto my-4 w-40;
}
</style>
