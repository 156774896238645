export const KEYCLOAK_CONFIGURATION = {
  development: {
    URL: 'https://stg-sso.solfacil.com.br',
    realm: 'dev',
    client: 'marketplace',
  },
  staging: {
    URL: 'https://stg-sso.solfacil.com.br',
    realm: 'General',
    client: 'marketplace',
  },
  hmla: {
    URL: 'https://stg-sso.solfacil.com.br',
    realm: 'hmla',
    client: 'marketplace',
  },
  hmle: {
    URL: 'https://stg-sso.solfacil.com.br',
    realm: 'hmle',
    client: 'marketplace',
  },
  hmle2: {
    URL: 'https://stg-sso.solfacil.com.br',
    realm: 'hmle2',
    client: 'marketplace',
  },
  hmli: {
    URL: 'https://stg-sso.solfacil.com.br',
    realm: 'hmli',
    client: 'marketplace',
  },
  hmli2: {
    URL: 'https://stg-sso.solfacil.com.br',
    realm: 'hmli2',
    client: 'marketplace',
  },
  hmlg2: {
    URL: 'https://stg-sso.solfacil.com.br',
    realm: 'hmlg2',
    client: 'marketplace',
  },
  production: {
    URL: 'https://sso.solfacil.com.br',
    realm: 'General',
    client: 'marketplace',
  },
};
