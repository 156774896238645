<template>
  <div
    v-if="filters.length"
    v-click-outside="hideFilters"
    class="sol-dropdown-filter"
    data-element="dropdown"
  >
    <span
      class="dropdown-trigger"
      :class="{ count, open: showFilters }"
      @click="toggleShowFilters"
    >
      <span v-if="count">{{ count }}</span>
      {{ label }}
      <IconFilter :class="{ 'icon-count': count || showFilters }" />
    </span>

    <div class="dropdown-content" :class="{ open: showFilters }">
      <nav>
        <ul>
          <template v-for="item in filterOptions">
            <li
              v-if="item.title"
              :key="item.field"
              :class="{
                active: activeFilter.title === item.title,
                selected: item.selected,
              }"
              @click="handleNav(item)"
            >
              {{ item.title }}
            </li>
          </template>
        </ul>
      </nav>

      <div class="dropdown-main">
        <Range
          v-if="activeFilter.type === 'range' && range"
          :key="keyRange"
          v-model="range"
          :min="activeFilter.min"
          :max="activeFilter.max"
          title="Modifique o minímo e máximo."
          @input="setRange"
        />

        <template v-if="activeFilter.type === 'switch'">
          <div v-if="activeFilter.label === 'Solfácil envios'">
            <div class="shipping-toogle">
              <ToggleSwitch
                id="filter-switch"
                :checked="isChecked()"
                @input="handleSwitch"
              />
              <img
                src="https://cdn.solfacil.com.br/frontoffice-loja/icons/shipping-solfacil-green.svg"
                alt="Caminhão solfacil envios"
                class="shipping-icon"
              />
            </div>
            <div class="shipping-toogle">
              <div class="shipping-icon-truck">
                <img
                  src="https://cdn.solfacil.com.br/frontoffice-loja/icons/shipping-truck-black.svg"
                  alt="Caminhão solfacil envios"
                />
              </div>
              Com a modalidade de entrega Solfácil envios, seu pedido será
              despachado em até 24hrs depois da confirmação do pagamento
            </div>
          </div>

          <div v-else class="dropdown-switch">
            {{ activeFilter.label }}
            <ToggleSwitch
              id="filter-switch"
              :checked="isChecked()"
              @input="handleSwitch"
            />
          </div>
        </template>

        <template v-if="activeFilter.type === 'radioButton'">
          <ul>
            <li
              v-for="(date, index) in filterDeliveryDeadline.filters"
              :key="date"
              @click="handleMaxDateLimit(date)"
            >
              <RadioButton
                :id="`radio-${index}`"
                :val="date"
                name="delivering_deadline"
                :label="`Até ${date} dias úteis`"
                :checked="filtersSelected.delivering_deadline == date"
              />
            </li>
          </ul>
        </template>

        <template v-if="activeFilter.type === 'checkbox'">
          <Input
            v-if="activeFilter.searchable"
            :id="`search-${activeFilter.field}`"
            v-model="searchString"
            :name="`search-${activeFilter.field}`"
            placeholder="Busque aqui"
            type="search"
            class="input"
            @input="searchHandler"
          />

          <ul @click.stop.prevent="">
            <li
              v-for="(item, index) in activeFilter.filters"
              :key="`${item.field}-${index}`"
              @click="handleSelectItem(item)"
            >
              {{ item }}
              <Checkbox
                :id="`checkbox-${item.field}-${index}`"
                :value="isItemSelected(item)"
              />
            </li>
          </ul>
        </template>

        <template v-if="activeFilter.field === 'code'">
          <Input
            :id="`search-code`"
            v-model="searchKitCode"
            :name="`search-code`"
            placeholder="Insira o código"
            type="search"
            class="input"
            @input="setKitCode"
          />
        </template>
      </div>

      <footer>
        <Button
          id="clear"
          variant="secondary"
          size="small"
          @click="clearFilters"
        >
          Limpar
        </Button>

        <Button id="aplly" size="small" @click="handleApplyFilters(true)">
          Aplicar
        </Button>
      </footer>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import {
  Button,
  Checkbox,
  Input,
  ToggleSwitch,
  RadioButton,
} from '@solfacil/components-ui';

import IconFilter from '@img/icons/icon-filter.svg';

export default {
  name: 'DropdownFilter',

  components: {
    Button,
    IconFilter,
    Checkbox,
    Input,
    RadioButton,
    ToggleSwitch,
    Range: () => import('@/components/shared/Range.vue'),
  },

  props: {
    label: {
      type: String,
      default: 'Filtro',
    },

    filters: {
      type: Array,
      required: true,
    },

    originalFilters: {
      type: Array,
      default: () => [],
    },

    selected: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      activeFilter: null,
      appliedFilters: {},
      count: null,
      filterOptions: null,
      filtersSelected: {},
      range: null,
      searchString: null,
      searchKitCode: null,
      showFilters: false,
      keyRange: 0,
      filterDeliveryDeadline: [],
    };
  },

  computed: {
    ...mapGetters('marketplace', ['newFiltersFlag']),

    filterPowerRange: function () {
      return this.filters.filter((filter) => filter.field === 'power_range')[0];
    },
  },

  async created() {
    this.filterOptions = this.getHasFilters();
    this.filterDeliveryDeadline = this.getFilterDeliveryDeadline();

    this.filtersSelected = cloneDeep(this.selected);
    this.handleApplyFilters(false);
    this.resetRange();
  },

  mounted() {
    if (this.selected?.code) {
      this.searchKitCode = this.selected.code;
    }
  },

  methods: {
    getFilterDeliveryDeadline() {
      return this.filterOptions.find(
        (item) => item.field === 'delivering_deadline',
      );
    },

    handleSwitch(isChecked) {
      this.filtersSelected[this.activeFilter.field] = isChecked;
      this.$forceUpdate();
      this.resetKitCode();
    },

    isChecked() {
      return this.filtersSelected[this.activeFilter?.field];
    },

    toggleShowFilters() {
      this.showFilters = !this.showFilters;
    },

    handleNav(item) {
      this.activeFilter = item;
    },

    isItemSelected(item) {
      return this.filtersSelected[this.activeFilter.field]
        ? this.filtersSelected[this.activeFilter.field].includes(item)
        : false;
    },

    setKitCode(val) {
      this.filtersSelected = { code: val };
    },

    resetKitCode() {
      this.searchKitCode = null;
      delete this.filtersSelected.code;
    },

    setRange(val) {
      this.filtersSelected[this.activeFilter.field] = val;
      this.resetKitCode();
    },

    resetRange(reset) {
      if (reset) {
        this.range = this.filterPowerRange.filters;
        return;
      }

      this.range = [
        this.filterPowerRange.filters,
        this.appliedFilters?.power_range,
      ][Number(this.appliedFilters?.power_range !== undefined)];
    },

    specialCharacters(value) {
      return String(value)
        .normalize('NFD')
        .replace(/[^\w\d\s]/gu, '')
        .toLowerCase();
    },

    searchHandler(filterSearch) {
      this.activeFilter.filters = this.getFiltersByField().filters.filter(
        (item) => this.isFilterSearchCharacters(item, filterSearch),
      );
    },

    isFilterSearchCharacters(item, filterSearch) {
      return this.specialCharacters(item).includes(
        this.specialCharacters(filterSearch),
      );
    },

    getFiltersByField() {
      return this.originalFilters.find(
        (item) => item.title === this.activeFilter.title,
      );
    },

    handleSelectItem(item) {
      this.isItemSelected(item) ? this.removeItem(item) : this.setItem(item);
      this.$forceUpdate();
      this.resetKitCode();
    },

    handleMaxDateLimit(date) {
      this.filtersSelected = {
        ...this.filtersSelected,
        delivering_deadline: Number(date),
      };
      this.resetKitCode();
    },

    setItem(item) {
      if (!this.filtersSelected[this.activeFilter.field])
        this.filtersSelected[this.activeFilter.field] = [];

      this.filtersSelected[this.activeFilter.field].push(item);
    },

    removeItem(item) {
      this.filtersSelected[this.activeFilter.field] = this.filtersSelected[
        this.activeFilter.field
      ].filter((i) => i !== item);

      !this.filtersSelected[this.activeFilter.field].length &&
        delete this.filtersSelected[this.activeFilter.field];
    },

    formatNumber(val) {
      if (!val) return val;

      return val.toLocaleString('pt-br', {
        minimumIntegerDigits: 2,
      });
    },

    hideFilters(event) {
      if (!this.showFilters || this.hasCloseSearchFilters(event)) return;

      this.appliedFilters?.power_range &&
        (this.range = [...this.appliedFilters?.power_range]);
      this.filtersSelected = cloneDeep(this.appliedFilters);
      this.showFilters = false;
      this.keyRange++;
    },

    hasCloseSearchFilters(event) {
      return (
        event.target.classList.contains('close') ||
        !event.target.classList.value
      );
    },

    handleApplyFilters(isFilter) {
      this.count = null;

      const filtersSelected = cloneDeep(this.filtersSelected);

      this.setAppliedFilters(filtersSelected);

      this.setFilterOptions();
      this.setFilterDefault();

      if (isFilter) {
        this.sendTrackingValues(filtersSelected);
        this.$emit('applyFilter', filtersSelected);
      }
    },

    setAppliedFilters(filtersSelected) {
      this.appliedFilters = filtersSelected;
    },

    setFilterOptions() {
      this.filterOptions.forEach((item) => {
        item.selected = false;

        if (this.appliedFilters) {
          this.setValuesFilter(item);
        }
      });
    },

    setFilterDefault() {
      this.count = this.formatNumber(this.count);
      this.activeFilter = this.filters[0];
      this.showFilters = false;
    },

    sendTrackingValues(filtersSelected) {
      this.sendTracking(
        'apply_filter',
        'ecommerce',
        this.getFilterValues(filtersSelected),
      );
    },

    getFilterValues(filtersSelected) {
      return {
        inverter_brand: filtersSelected?.inverter_brand,
        roof_type: filtersSelected?.roof_type,
        minimum_power: this.getMinimumPower(filtersSelected),
        maximum_power: this.getMaximumPower(filtersSelected),
        inverter_connection: filtersSelected?.inverter_connection,
        module_brand: filtersSelected?.module_brand,
        inverter_type: filtersSelected?.inverter_type,
        availability: filtersSelected?.kit_availability_deadline,
        unloading: filtersSelected?.kit_unloading,
        solfacil_envios: filtersSelected?.kit_own_logistic,
        delivery_date: filtersSelected?.delivering_deadline,
        module_power: filtersSelected?.module_power,
        module_amount: filtersSelected?.module_amount,
        code: filtersSelected?.code,
        flag: this.newFiltersFlag,
      };
    },

    getMinimumPower(filtersSelected) {
      return filtersSelected.power_range ? filtersSelected.power_range[0] : [];
    },

    getMaximumPower(filtersSelected) {
      return filtersSelected.power_range ? filtersSelected.power_range[1] : [];
    },

    setValuesFilter(item) {
      Object.keys(this.appliedFilters).forEach((filter) => {
        if (item.field === filter) {
          if (filter === 'power_range') {
            this.addCountFilters(item, filter);
            return;
          }

          item.selected = true;
          this.setCountFilters(filter);
        }
      });

      this.removeFilterWhenBooleanFalse();
    },

    removeFilterWhenBooleanFalse() {
      if (!this.appliedFilters[this.activeFilter?.field]) {
        Object.keys(this.appliedFilters).forEach((item) => {
          if (item === this.activeFilter?.field) {
            delete this.appliedFilters[item];
            delete this.filtersSelected[item];
          }
        });
      }
    },

    addCountFilters(item, filter) {
      if (
        this.appliedFilters[filter].toString() !==
        this.filterOptions[0].filters.toString()
      ) {
        this.count++;
        item.selected = true;
      }
    },

    setCountFilters(filter) {
      const ITEM_FILTER_BOOLEAN = 1;

      this.count += Array.isArray(this.appliedFilters[filter])
        ? this.appliedFilters[filter].length
        : ITEM_FILTER_BOOLEAN;
    },

    clearFilters() {
      this.count = null;
      this.appliedFilters = {};
      this.filtersSelected = {};
      this.filterOptions = this.resetAllSelectedFilter(this.getHasFilters());
      this.resetRange(true);
      this.activeFilter = this.filters[0];
      this.showFilters = false;
      this.$emit('applyFilter', this.filtersSelected);
    },

    resetAllSelectedFilter(filters) {
      filters.forEach((value) => {
        if (value.selected) {
          value.selected = false;
        }
      });

      return filters;
    },

    getHasFilters() {
      return this.filters.filter(
        (value) =>
          (value.filters?.length && this.isFilterSwitch(value)) ||
          value.type === 'input',
      );
    },

    isFilterSwitch(value) {
      return value.filters.some((item) => item !== 'false');
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-toogle {
  @apply flex;
}

.shipping-icon {
  @apply mt-1 ml-2;
}

.shipping-icon-truck {
  @apply mr-2 mt-2;

  img {
    @apply w-9;
  }
}

.sol-dropdown-filter {
  @apply relative z-20;

  .dropdown-trigger {
    @apply bg-white inline-flex items-center gap-4 text-grayPrimary text-small leading-tight font-normal rounded px-4 h-8 border-solid border border-gray3 font-rubik cursor-pointer duration-200 ease-linear transition-all;
    padding-top: 2px;

    &.open,
    &.count {
      @apply border-grayPrimary relative;
    }

    span {
      @apply w-6 h-6 rounded-full text-center border border-solid bg-rebrandingPrimary font-rubik text-slight font-normal text-white absolute;
      border-color: darken(#008859, 25%);
      line-height: 23px;
      right: -12px;
      top: -12px;
    }

    .icon-count path {
      fill: #666;
    }
  }

  .dropdown-content {
    @apply bg-white flex-wrap shadow rounded overflow-hidden opacity-0 invisible duration-200 ease-linear transition-all absolute left-0;
    top: 100%;
    min-width: 330px;

    @screen lg {
      @apply bg-white flex flex-wrap shadow rounded overflow-hidden opacity-0 invisible duration-200 ease-linear transition-all absolute left-0;
      width: 570px;
    }

    &.open {
      @apply opacity-100 visible;
      top: calc(100% + 16px);

      li {
        text-align: start;
      }
    }

    nav {
      width: 100%;

      height: 250px;
      overflow: auto;

      @screen lg {
        width: 200px;
      }

      ul {
        li {
          @apply bg-white flex-1 items-center p-4 font-rubik text-small text-grayPrimary font-normal border-t border-gray3 border-solid cursor-pointer duration-200 ease-linear transition-all gap-2;
          line-height: 1.7;

          &.active {
            @apply bg-gray1 cursor-default;
          }

          &.selected {
            @apply pl-2;
            display: flex;

            &::before {
              @apply block w-2 h-2 rounded-full bg-rebrandingPrimary;
              content: '';
            }
          }

          &:first-child {
            @apply border-none;
          }
        }
      }
    }

    .dropdown-switch {
      @apply flex justify-between p-2;
      border-bottom: 1px solid #e0e0e0;
    }

    .dropdown-main {
      @apply p-4 border-l border-gray3 border-solid overflow-auto;

      &::-webkit-scrollbar {
        @apply w-2;
      }
      width: 100%;
      height: 260px;

      @screen lg {
        width: 370px;
      }

      ul {
        @apply overflow-hidden overflow-y-auto;
        height: 228px;

        // custom scroll
        &::-webkit-scrollbar {
          @apply w-2;
          right: 16px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          @apply bg-gray2;
          border-radius: 25px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          @apply bg-gray3;
          border-radius: 25px;

          &:hover {
            @apply bg-gray4;
          }
        }

        li {
          @apply flex justify-between p-2 pl-4 border-t border-solid border-gray3;

          &:first-child {
            @apply border-none;
          }
        }
      }

      .sol-input + ul {
        height: 164px;
      }
    }

    footer {
      @apply bg-gray2 flex justify-end p-4 gap-4 w-full;

      bottom: 0px;
      left: 0px;
    }
  }
}
</style>

<style lang="scss">
.sol-dropdown-filter {
  .input {
    @apply mb-4;

    input {
      @apply h-9;
    }
  }
}
</style>
