<template>
  <section class="stars">
    <svg
      v-for="index in numberOfStars"
      :key="index"
      height="24"
      width="24"
      class="star"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :class="{ '-filled': index <= fillRating }"
      @click="$emit('input', index)"
      @mouseover="setHover(index)"
      @mouseleave="hoverRating = null"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        class="star-body"
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      />
    </svg>
  </section>
</template>

<script>
export default {
  name: 'RatingStars',

  props: {
    numberOfStars: {
      type: Number,
      default: () => 5,
    },
    value: {
      type: Number,
      default: () => 1,
    },
  },
  data: () => ({
    hoverRating: null,
  }),

  computed: {
    fillRating() {
      return this.hoverRating ? this.hoverRating : this.value;
    },
  },

  methods: {
    setHover(index) {
      this.hoverRating = index;
    },
  },
};
</script>

<style lang="scss">
.stars {
  @apply flex;
  > .star {
    display: block;
    margin-right: 10px;
    cursor: pointer;
    &.-filled > .star-body {
      stroke: #ffb300;
      fill: #ffb300;
    }
    > .star-body {
      stroke: #ffb300;
      fill: none;
    }
  }
}
</style>
