import Vue from 'vue';
import Keycloak from 'keycloak-js';
import { KEYCLOAK_CONFIGURATION } from '@/utils/keycloakConfiguration.js';

export default () => {
  let url = KEYCLOAK_CONFIGURATION[Vue.prototype.$env].URL;
  let realm = KEYCLOAK_CONFIGURATION[Vue.prototype.$env].realm;
  let client = KEYCLOAK_CONFIGURATION[Vue.prototype.$env].client;

  const options = {
    oidcProvider: `${url}/realms/${realm}`,
    clientId: client,
  };

  const _keycloak = new Keycloak(options);
  Vue.$keycloak = _keycloak;
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak;
      },
    },
  });
};
