import { getFormattedProductsPriceInOrder } from '@/helper/getFormattedProductsPrice.helper';
import { VIA_CEP } from '@/helper/constants.helper';
import { DataLoader } from '@/services/dataLoader';
import { socket, Notify } from '@/services';
import { errorMessage as hasErrorMessageOpenModal } from '@/helper/errorMessage.helper';

import * as AbsintheSocket from '@absinthe/socket';
import { Socket as PhoenixSocket } from 'phoenix';

let absintheSocket;
let notifier;

const state = () => ({
  order: null,
  registerOrder: null,
  orderSuccess: null,
  ordersDelivered: [],
  listNotifications: [],
});

const actions = {
  setOrder({ commit }, value) {
    commit('SET_ORDER', value);
  },

  async searchCep({ dispatch }, cep) {
    dispatch('loading/showLoad', null, { root: true });

    try {
      const response = await fetch(`${VIA_CEP}${cep}/json/`);

      const data = await response.json();
      dispatch('loading/hideLoad', null, { root: true });

      if (data.erro) {
        const error = {
          type: 'warning',
          message: 'Não conseguimos encontrar o endereço para o CEP informado!',
        };
        Notify(error, this);

        return false;
      }

      return data;
    } catch (e) {
      e.type = 'error';
      e.message = 'Ocorreu um erro, por favor informe o endereço.';
      Notify(e, this);

      dispatch('loading/hideLoad', null, { root: true });

      return false;
    }
  },

  async registerOrder({ commit, dispatch, state }, value) {
    dispatch('loading/showLoad', null, { root: true });
    const payload = {
      query: `
        mutation(
          $observation: String,
          $products: [InputProductList]!,
          $address: InputAddressDetail!,
          $full_name: String,
          $phone_number: String,
          $email: String,
          $payment: InputPayment!,
          $partner_customer_name: String,
          $partner_customer_email: String,
          $partner_customer_phone_number: String,
          $acquisition_target: AcquisitionTargetType
        ) {
          create_order(
            observation: $observation,
            products: $products,
            address: $address,
            full_name: $full_name,
            phone_number: $phone_number,
            email: $email,
            payment: $payment,
            partner_customer_name: $partner_customer_name,
            partner_customer_email: $partner_customer_email,
            partner_customer_phone_number: $partner_customer_phone_number,
            acquisition_target: $acquisition_target
          )
          {
            id
            code
            observation
            email
            fullName
            phoneNumber
            partnerCustomerEmail
            partnerCustomerName
            partnerCustomerPhoneNumber
            financing_id
            total_amount
            total_discount
            total_paid
            details {
              address {
                street
                neighborhood
                complement
                zip_code
                city
                state
                number
              }
              products {
                id
                sku
                price
                discounted_price
                description
                supplier_id
                supplierName: supplier_name
                type
                power
                roofType: roof_type
                amount
                inserted_at
                attributes
                kitInverters: kit_inverters {
                  amount
                  item {
                    id
                    sku
                    price
                    description
                    supplier_id
                    supplierName: supplier_name
                    type
                    power
                    attributes
                  }
                }
                kitModules: kit_modules {
                  amount
                  item {
                    id
                    sku
                    price
                    description
                    supplier_id
                    supplierName: supplier_name
                    type
                    power
                    attributes
                  }
                }
                 shipping{
                    id,
                    cost,
                    min_date_limit,
                    max_date_limit,
                    updated_at
                }
              }
            }
            payments {
              documents {
                url
                filename
                content_type
                type
                inserted_at
                updated_at
                document_number
              }
              financingBank
              confirmed
              method
              client_type
              inserted_at
              updated_at
            }
          }
        }
      `,
      variables: value,
    };

    try {
      const response = await DataLoader.post(payload, false);

      if (response.errors) {
        const error = { type: 'error', message: response.errors[0].message };

        if (!hasErrorMessageOpenModal(error.message)) {
          Notify(error, this);
        }
        return;
      }

      const order = global.structuredClone(
        getFormattedProductsPriceInOrder(response.data.create_order),
      );

      commit('SET_REGISTER_ORDER', order);
      dispatch('loading/hideLoad', null, { root: true });

      let cache = 0;
      let lastIdx = 0;

      await Promise.all(
        order.map((order) => {
          order.payments[0].documents.map((doc) => {
            cache = 0;
            lastIdx = 0;
            Object.keys(state.order.payment.documents).map((file) => {
              if (state.order.payment.documents[file].length) {
                cache += lastIdx + 1;

                if (Array.isArray(state.order.payment.documents[file])) {
                  state.order.payment.documents[file].map((item, idx) => {
                    lastIdx = idx;
                    const itemName = `${file}_${cache + idx}_${item.name}`;
                    if (doc.filename === itemName) {
                      return new Promise((resolve) => {
                        fetch(doc.url, {
                          method: 'PUT',
                          body: item,
                        }).then(() => new Promise(() => resolve()));
                      });
                    }
                  });
                }
              }
            });
          });
        }),
      ).then(() => {
        commit('SET_ORDER_SUCCESS', true);
      });

      return true;
    } catch (e) {
      const error = { type: 'error', message: e.message };

      if (!hasErrorMessageOpenModal(error.message)) {
        error.type = 'error';
        Notify(error, this);
      }

      dispatch('loading/hideLoad', null, { root: true });

      return false;
    }
  },

  loadNotifications: async ({ commit }) => {
    const payload = {
      query: `
        {
          listUnviewedNotifications {
            id
            message
            orderDeliveredAt
            orderId
            type
            userId
            viewed
          }
        }
      `,
    };

    try {
      const response = await DataLoader.post(payload, true);

      if (response.errors) {
        const e = { type: 'error', message: response.errors[0].message };
        Notify(e, this);
        return;
      }

      commit('SET_LIST_NOTIFICATIONS', response.data.listUnviewedNotifications);
      commit('SET_ORDERS_DELIVERED', getters.orderIds(response));

      return true;
    } catch (e) {
      e.type = 'error';
      Notify(e, this);
      return;
    }
  },

  markNotificationAsRead: async ({ dispatch }, id, orderDeliveredAt) => {
    const payload = {
      query: `
        mutation(
        $id: Int!
        $orderDeliveredAt: Date
      ){
        updateViewedNotification(
          id: $id
          orderDeliveredAt: $orderDeliveredAt
        ){
          id
          message
          orderDeliveredAt
          orderId
          type
          userId
          viewed
        }
      }
    `,
      variables: { id, orderDeliveredAt },
    };
    try {
      const response = await DataLoader.post(payload, true);

      if (response.errors) {
        const e = { type: 'error', message: response.errors[0].message };
        Notify(e, this);
        return;
      }

      dispatch('loadNotifications');

      return true;
    } catch (e) {
      e.type = 'error';
      Notify(e, this);
      return;
    }
  },

  async receivingNotifications({ dispatch }) {
    const user = JSON.parse(localStorage.getItem('user'));

    const operation = `
      subscription {
        newNotification(userId: "${user?.id}") {
          id
          message
          orderDeliveredAt
          orderId
          type
          userId
          viewed
        }
      }
    `;

    try {
      absintheSocket = AbsintheSocket.create(
        new PhoenixSocket(`${socket.baseURL}/socket`),
      );

      notifier = AbsintheSocket.send(absintheSocket, {
        operation,
      });

      AbsintheSocket.observe(absintheSocket, notifier, {
        onResult: () => {
          dispatch('loadNotifications');
        },
      });

      return true;
    } catch (e) {
      this._vm.$toaster.error(e, {
        id: `error-socket-${Math.random().toString(36).substr(2, 9)}`,
      });

      return false;
    }
  },

  createOrderRating: async (_, { id, observation, rating }) => {
    const payload = {
      query: `
       mutation (
        $id: UUID4!
        $observation: String
        $rating: Int!
      ) {
        createOrderRating(
          id: $id
          observation: $observation
          rating: $rating
        ) {
          id
          rating
          observation
        }
      }
    `,
      variables: { id, observation, rating },
    };
    try {
      const response = await DataLoader.post(payload, true);

      if (response.errors) {
        const e = { type: 'error', message: response.errors[0].message };
        Notify(e, this);
        return;
      }

      return true;
    } catch (e) {
      e.type = 'error';
      Notify(e, this);
      return;
    }
  },

  resetOrderSuccess({ commit }) {
    commit('RESET');
  },
};

const mutations = {
  SET_ORDER(state, result) {
    state.order = result;
  },

  SET_ORDER_SUCCESS(state, result) {
    state.orderSuccess = result;
  },

  SET_ORDERS_DELIVERED(state, result) {
    state.ordersDelivered = result;
  },

  SET_LIST_NOTIFICATIONS(state, result) {
    state.listNotifications = result;
  },

  SET_REGISTER_ORDER(state, result) {
    state.registerOrder = result;
  },

  RESET(state) {
    state.order = null;
    state.registerOrder = null;
    state.orderSuccess = null;
  },
};

const getters = {
  order: (state) => state.order,
  resultRegisterOrder: (state) => state.registerOrder,
  orderSuccess: (state) => state.orderSuccess,
  ordersDelivered: (state) => state.ordersDelivered,
  listNotifications: (state) => state.listNotifications,
  orderIds: (response) => {
    return response.data.listUnviewedNotifications.map(
      (order) => order.orderId,
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
