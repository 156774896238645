<template>
  <div class="shipping-info">
    <div class="shipping-info__title">
      <h1>
        Quem gosta de benefícios, vai de Solfácil
        <ShippingSolfacil />
      </h1>
    </div>
    <div class="shipping-info__paragraph">
      <p>
        A solfácil envios é a nossa logística própria, com ela garantimos a sua
        entrega. Caso houver algum problema com seu kit, garantimos o envio de
        um novo.
      </p>
    </div>
    <div class="shipping-info__info">
      <p>
        <IconClock class="icon-shipping" />Seu pedido na rua em até 24 horas
        após aprovação do pagamento
      </p>
      <p><IconCart class="icon-shipping" />Frete Incluso no valor do produto</p>
      <p>
        <IconTruck class="icon-shipping" />Descarregamento incluso no destino
        final
      </p>
      <p>
        <IconFlag class="icon-shipping" />Acompanhamento detalhado do pedido
      </p>
    </div>
  </div>
</template>

<script>
import ShippingSolfacil from '@img/shipping-solfacil-white.svg';
import IconCart from '@img/icons/icon-cart.svg';
import IconClock from '@img/icons/icon-clock.svg';
import IconFlag from '@img/icons/icon-flag.svg';
import IconTruck from '@img/icons/icon-truck.svg';
export default {
  name: 'ShippingInfo',
  components: {
    IconCart,
    IconClock,
    IconFlag,
    IconTruck,
    ShippingSolfacil,
  },
};
</script>

<style lang="scss" scoped>
.icon-shipping {
  @apply inline mr-2 mb-1;
}
.shipping-info {
  &__title {
    @apply text-modest block mb-2 font-medium;
    svg {
      @apply inline mb-1;
    }
  }
  &__paragraph {
    @apply mb-1 text-small font-normal;
  }
  &__info {
    @apply uppercase text-tiny font-bold mt-2;
    p {
      @apply mb-2;
    }
  }
}
</style>
