<template>
  <Modal
    v-if="isOpen && hasChange"
    id="product-updates"
    class="product-updates"
    size-lg
    title="Atenção parceiro"
    :self-close="false"
    :button-close="isDefaultFooter() ? 'primary' : null"
    :value="!!isOpen"
    @closeModal="keepProducts"
  >
    <p v-if="alteredPriceProducts.length">
      Houve uma alteração de preço nos produtos abaixo.
    </p>
    <div
      v-for="product in alteredPriceProducts"
      :key="product.id"
      class="modal-content"
    >
      <p class="product-info">
        {{ inverterBrand(product.node) }} - {{ inverterPower(product.node) }}
      </p>
      <p class="product-info">
        {{ moduleBrand(product.node) }} - {{ modulePower(product.node) }}
      </p>
      <p class="product-info">Telhado {{ product.node.roofType }}</p>
      <p class="product-info">Frete</p>
      <p class="product-info">Qtde {{ product.node.amount }}</p>

      <div class="prices">
        <span class="old-price"
          >De:<span>{{
            product.node.discounted_price ||
            product.node.price | currencyInteger
          }}</span></span
        >
        <span class="new-price"
          >Para:
          <span :class="`price-${getPriceChange(product)}`">
            {{ product.newPrice | currencyInteger }}
          </span>
          <IconArrowDown :class="`icon-price-${getPriceChange(product)}`" />
        </span>
      </div>
    </div>

    <p v-if="unavailableProducts.length">
      Infelizmente os produtos abaixo estão indisponíveis.
    </p>
    <div
      v-for="product in unavailableProducts"
      :key="product.id"
      class="modal-content"
    >
      <p class="product-info">
        {{ inverterBrand(product.node) }} - {{ inverterPower(product.node) }}
      </p>
      <p class="product-info">
        {{ moduleBrand(product.node) }} - {{ modulePower(product.node) }}
      </p>
      <p class="product-info">Telhado {{ product.node.roofType }}</p>
      <p class="product-info">Frete</p>
      <p class="product-info">Qtde {{ product.node.amount }}</p>
      <p class="product-info price">
        {{ product.node.price | currencyInteger }}
      </p>
    </div>

    <template v-if="!isDefaultFooter()" #footer>
      <p>O que você deseja fazer?</p>

      <Button id="keep-product" @click="keepProducts"> Manter </Button>
      <Button
        id="select-other-product"
        variant="secondary"
        @click="selectOtherProducts(alteredPriceProducts)"
      >
        Selecionar outro
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal } from '@solfacil/components-ui';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import IconArrowDown from '@img/icons/icon-arrow-down.svg';
import attributesKit from '@/mixins/attributesKit';

export default {
  name: 'ModalProductsUpdate',

  components: {
    Button,
    Modal,
    IconArrowDown,
  },

  mixins: [attributesKit],

  props: {
    isOpen: {
      default: false,
      required: true,
      type: [Boolean, Number],
    },
  },

  data() {
    return {
      cartSkus: [],
      cartUpdated: [],
      alteredPriceProducts: [],
      unavailableProducts: [],
    };
  },

  computed: {
    ...mapGetters({
      products: 'marketplace/products',
      listProductsCart: 'marketplace/listProductsCart',
      order: 'order/order',
      locationAddress: 'marketplace/locationAddress',
    }),

    hasLocationAddress() {
      return this.locationAddress.city && this.locationAddress.state;
    },

    hasChange() {
      return (
        !!this.alteredPriceProducts.length || !!this.unavailableProducts.length
      );
    },
  },

  watch: {
    isOpen() {
      this.verifyUpdates();
    },
  },

  created() {
    this.verifyUpdates();
  },

  methods: {
    ...mapActions({
      setCart: 'marketplace/setCart',
      getCartUpdate: 'marketplace/getCartUpdate',
      setOrder: 'order/setOrder',
    }),

    getPriceChange({ node, newPrice }) {
      if (node.discounted_price)
        return ['increased', 'decreased'][
          Number(newPrice < node.price && newPrice < node.discounted_price)
        ];
      return ['increased', 'decreased'][Number(newPrice < node.price)];
    },

    getSkus(products) {
      return products.map((product) => {
        return `"${product.node.sku}"`;
      });
    },

    isDefaultFooter() {
      const onlyUnavailableProduct =
        !this.alteredPriceProducts.length && this.unavailableProducts.length;
      return Boolean(this.isOnHome() || Boolean(onlyUnavailableProduct));
    },

    isOnHome() {
      return this.$route.name === 'home';
    },

    getAlteredPriceProducts(listProductsCart, cartUpdate) {
      const newCart = listProductsCart.reduce((acumulator, product) => {
        const productUpdated = cartUpdate.filter((currentProduct) => {
          if (currentProduct.node.sku === product.node.sku)
            return currentProduct;
        });

        if (this.priceChanges(productUpdated, product)) {
          return [
            ...acumulator,
            {
              id: product.node.id,
              newPrice:
                productUpdated[0].node.discounted_price ||
                productUpdated[0].node.price,
              node: product.node,
            },
          ];
        }

        this.$emit('isChangePrice', false);

        return [...acumulator];
      }, []);
      return newCart;
    },

    priceChanges(productUpdated, product) {
      return (
        productUpdated.length &&
        (productUpdated[0].node.price !== product.node.price ||
          productUpdated[0].node.discounted_price !==
            product.node.discounted_price)
      );
    },

    getUnavailableProducts(listProductsCart, cartUpdate) {
      const skusUpdated = this.getSkus(cartUpdate);

      return listProductsCart.filter((product) => {
        if (!skusUpdated.includes(`"${product.node.sku}"`)) {
          return product;
        }
      });
    },

    updatePricesCart() {
      const cartUpdate = this.cartUpdated;
      const listProductsCart = this.listProductsCart;
      this.$emit('isChangePrice', true);

      const newProductCart = listProductsCart.reduce((acumulator, product) => {
        const promotions_ids = this.getPromotionIds(
          product.node.applied_promotions,
        );
        const currentProduct = cartUpdate.filter((productUpdate) => {
          return productUpdate.node.sku == product.node.sku;
        });
        if (currentProduct.length) {
          acumulator = [
            ...acumulator,
            {
              cursor: currentProduct?.[0]?.cursor ?? '',
              node: {
                amount: product.node.amount,
                priceLogCursor: product.node.priceLogCursor,
                promotions_ids: promotions_ids,
                ...currentProduct[0].node,
                shipping: this.getShippingCompletedInfo(product),
              },
            },
          ];
        }
        return acumulator;
      }, []);

      this.setCart(newProductCart);

      let order = { ...cloneDeep(this.order) };
      if (order.products?.length) {
        order.products = newProductCart.map((product) => {
          const promotions_ids = this.getPromotionIds(
            product.node.applied_promotions,
          );
          return {
            amount: product.node.amount,
            price_log_cursor: product.node.priceLogCursor,
            promotions_ids: promotions_ids,
            product_id: product.node.id,
            shipping: this.getShippingIdUpdateAt(product),
          };
        });
        this.setOrder(order);
      }
    },

    getShippingIdUpdateAt(product) {
      return {
        id: product.node?.shipping?.id,
        updated_at: product.node?.shipping?.updated_at,
      };
    },

    getShippingCompletedInfo(product) {
      return {
        id: product.node?.shipping?.id,
        updated_at: product.node?.shipping?.updated_at,
        min_date_limit: product.node?.shipping?.min_date_limit,
        max_date_limit: product.node?.shipping?.max_date_limit,
      };
    },

    removeProducts(productsChanged) {
      const skusForRemove = productsChanged.map(
        (productChange) => productChange.node.sku,
      );
      const newCart = this.listProductsCart.filter(
        (product) => !skusForRemove.includes(product.node.sku),
      );

      this.setCart(newCart);
    },

    selectOtherProducts(productsChanged) {
      this.removeProducts(this.unavailableProducts);
      this.removeProducts(productsChanged);
      this.$router.push('/');
    },

    async verifyUpdates() {
      this.cartSkus = this.getSkus(this.listProductsCart);

      if (this.hasLocationAddress) {
        this.cartUpdated = await this.getCartUpdate(this.cartSkus);
      }

      if (this.cartUpdated?.length) {
        let newListProductsCart = JSON.parse(
          JSON.stringify(this.listProductsCart),
        );
        let cartUpdate = JSON.parse(JSON.stringify(this.cartUpdated));

        this.alteredPriceProducts = this.getAlteredPriceProducts(
          newListProductsCart,
          cartUpdate,
        );

        if (newListProductsCart.length !== cartUpdate.length) {
          this.unavailableProducts = this.getUnavailableProducts(
            newListProductsCart,
            cartUpdate,
          );
        }

        this.$emit(
          'input',
          this.alteredPriceProducts.length || !!this.unavailableProducts.length,
        );
        return;
      }

      this.unavailableProducts = this.listProductsCart;
    },

    getPromotionIds(promotions) {
      return promotions.map((promotion) => promotion.external_id);
    },

    async keepProducts() {
      await this.removeProducts(this.unavailableProducts);
      this.updatePricesCart();
      this.unavailableProducts = [];
      this.closeModal();

      if (!this.listProductsCart.length) this.$router.push('/');
    },

    closeModal() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss">
.product-updates {
  .product-info {
    @apply mt-1;
  }
  .price {
    @apply font-bold mt-2 text-modest;
  }

  footer {
    @apply h-auto flex-wrap #{!important};

    p {
      @apply mb-2 w-full;
    }
    button {
      @apply mr-4;
    }
  }

  .modal-content {
    @apply border rounded p-4 mt-4 mb-4 border-gray3;

    .prices {
      @apply flex justify-between align-middle mt-3;

      .old-price {
        @apply line-through font-bold mt-1 text-modest;
      }

      .new-price {
        @apply font-bold text-right flex flex-row items-center gap-1 #{!important};

        .price-increased {
          @apply text-medium text-red2;
        }
        .price-decreased {
          @apply text-medium text-green2;
        }
        .icon-price-increased {
          transform: rotate(180deg);
          path {
            stroke: #ff7771;
          }
        }
        .icon-price-decreased path {
          stroke: #4cd89d;
        }
      }
    }
  }
}
</style>
