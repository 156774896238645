<template>
  <nav class="sidebar" :class="{ open: statusHamburger }">
    <ul role="menu">
      <li v-for="(item, index) in navbar" :key="index">
        <span> {{ item.title }} </span>

        <ul>
          <li v-for="(link, i) in item.links" :key="i" :data-id="link.dataId">
            <a v-if="link.url" :href="link.url" :title="link.title">
              {{ link.title }}
            </a>

            <strong v-else-if="checkSublinksAvailable(link.subLinks)">
              {{ link.title }}
            </strong>

            <router-link
              v-else-if="!link.hide && !link.subLinks"
              class="item"
              :title="link.title"
              :to="link.to"
              :role="link.role"
            >
              {{ link.title }}
            </router-link>

            <ul>
              <li v-for="(subLink, j) in link.subLinks" :key="j">
                <router-link
                  v-if="!subLink.hide"
                  :title="subLink.title"
                  :to="subLink.to"
                  :role="subLink.role"
                  class="sublink-item"
                >
                  • {{ subLink.title }}
                  <span v-if="hasNotification(subLink)" class="notification">{{
                    ordersDelivered.length
                  }}</span>
                  <template
                    v-if="
                      subLink.title === 'Carrinho' && minCart && minCart.amount
                    "
                  >
                    ({{ minCart.amount }})
                  </template>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { TokenService } from '@/services';
import getURL from '@/mixins/getURL';

export default {
  name: 'TheSideBar',

  mixins: [getURL],

  props: {
    flags: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters({
      statusHamburger: 'hamburger/statusHamburger',
      minCart: 'marketplace/minCart',
      ordersDelivered: 'order/ordersDelivered',
    }),

    navbar() {
      return [
        {
          title: 'Produtos',
          links: [
            {
              title: 'Homologações',
              dataId: 'nav-homologacoes',
              url: `https:${this.getUrlSolfacil()}homologacao/informativo_geral`,
            },
            {
              title: 'Financiamentos',
              dataId: 'nav-financiamentos',
              url: `https:${this.getUrlSolfacil()}financiamentos`,
            },
            {
              title: 'Monitoramento',
              dataId: 'nav-monitoramento',
              url: this.getUrlMonitoring(),
            },
          ],
        },
        {
          title: 'Benefícios',
          links: [
            {
              title: 'Solfácil Mais',
              dataId: 'nav-solfacil-mais',
              url: this.getUrlSolfacilMais(),
            },
            {
              title: 'Loja',
              dataId: 'nav-marketplace',
              subLinks: [
                {
                  title: 'Comprar',
                  to: '/loja',
                  role: 'menuitem',
                },
                {
                  title: 'Carrinho',
                  to: '/loja/carrinho',
                  role: 'menuitem',
                },
                {
                  title: 'Meus pedidos',
                  to: '/loja/meus-pedidos',
                  role: 'menuitem',
                  hide: this.hideMyOrders(),
                },
              ],
            },
          ],
        },
      ];
    },
  },

  methods: {
    checkSublinksAvailable(subLinks) {
      return subLinks ? !!subLinks.find((s) => !s.hide) : false;
    },

    hideMyOrders() {
      return ['admin', 'avulso', 'fornecedor'].includes(
        TokenService.getUser()?.profile,
      );
    },

    hasNotification(subLink) {
      const hasNotification = this.ordersDelivered.length;
      return subLink.title === 'Meus pedidos' && hasNotification;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  @apply w-full h-full fixed bottom-0 bg-white font-rubik pb-6 border-r border-gray3 duration-200 ease-linear transition-all z-30;
  max-height: calc(100vh - 135px);
  right: 100%;

  > ul {
    @apply overflow-auto max-h-full;

    // custom scroll
    &::-webkit-scrollbar {
      @apply w-2;
      right: 16px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      @apply bg-gray2;
      border-radius: 25px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      @apply bg-gray3;
      border-radius: 25px;

      &:hover {
        @apply bg-gray4;
      }
    }

    &::-webkit-scrollbar-button {
      @apply hidden;
    }

    li {
      span {
        @apply uppercase text-tiny text-rebrandingPrimary font-bold px-6 relative mt-6 mb-2 block;

        &:before {
          @apply bg-rebrandingPrimary w-4 block absolute;
          content: '';
          height: 2px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      a {
        @apply block text-grayPrimary text-modest py-4 px-6 duration-200 ease-linear transition-all border-b border-gray3;

        &:hover,
        &.router-link-exact-active {
          @apply bg-gray1 text-rebrandingPrimary font-rubik font-medium;
        }

        &.item.router-link-exact-active {
          @apply bg-gray1 font-medium font-rubik text-grayPrimary;
        }
      }

      li {
        strong {
          @apply block py-4 px-6 text-modest font-medium border-b border-gray3;
        }

        li {
          a {
            @apply text-small px-7 py-2;
          }
        }
      }
    }
  }

  &.open {
    @apply right-0;
  }

  .sublink-item {
    @apply flex;

    .notification {
      @apply h-4 w-4 my-0 ml-1 px-0 rounded-full text-center border border-solid bg-rebrandingPrimary font-normal text-white;
      line-height: 14px;
      &::before {
        @apply w-0;
      }
    }
  }

  @screen lg {
    @apply right-auto left-0;
    max-width: 260px;
    max-height: calc(100vh - 74px);

    &.open {
      @apply right-auto left-0;
      max-width: 260px;
    }
  }
}
</style>
