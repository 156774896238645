<template>
  <div class="ct-details" @click="handleClickDetails">
    <img src="@img/icons/icon-detail.svg?inline" alt="detail icon" />
    <p>VER DETALHES</p>
  </div>
</template>

<script>
export default {
  name: 'HomeDetails',

  methods: {
    handleClickDetails() {
      this.$emit('onDetailsClick');
    },
  },
};
</script>
<style lang="scss" scoped>
.ct-details {
  @apply flex flex-col items-center cursor-pointer;
  @screen xl {
    @apply flex-row;
  }
  img {
    @apply w-5 ml-1;
    @screen xl {
      @apply w-6;
    }
  }
  p {
    @apply w-20;
    font-size: 10px;
    color: #212121;
    @screen xl {
      @apply text-xs;
      width: 90px;
    }
  }
}
</style>
