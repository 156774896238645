<template>
  <header>
    <a :href="homeUrl" title="Home">
      <img :src="logo" alt="Solfacil" class="logo" />
    </a>

    <nav>
      <ul role="menu">
        <li v-if="userName" role="none">
          <span>Bem vindo {{ userName }}</span>
        </li>

        <li
          v-if="isCustomer && levelCustomer > 0"
          role="level"
          class="score-tag"
        >
          <iconLevel class="score-icon" />
          <a :href="`${getUrlSolfacilMais()}auth/login`" target="_blank">
            Nível {{ levelCustomer }}</a
          >
        </li>

        <li role="none">
          <a
            title="Senha"
            role="menuitem"
            :href="`https:${getUrlSolfacil()}auth/change-password`"
          >
            <iconChangePassword />
            <span>Alterar Senha</span>
          </a>
        </li>

        <li role="none">
          <a title="Sair" role="menuitem" @click="logout">
            <iconLogout />
            <span>Sair</span>
          </a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { ApiService, TokenService } from '@/services';
import getURL from '@/mixins/getURL';
import iconChangePassword from '@img/icons/icon-change-password.svg';
import iconLogout from '@img/icons/icon-logout.svg';
import iconLevel from '@img/icons/icon-level.svg';

export default {
  name: 'TheHeader',

  mixins: [getURL],

  components: {
    iconChangePassword,
    iconLogout,
    iconLevel,
  },

  computed: {
    userName() {
      return TokenService.getUser()?.name;
    },
    isCustomer() {
      return Boolean(TokenService.getUser()?.customer_id);
    },
    levelCustomer() {
      return TokenService.getUser()?.level;
    },

    logo() {
      return [
        'https://cdn.solfacil.com.br/assets/img/logo/solfacil-logo-small.svg?inline',
        'https://cdn.solfacil.com.br/assets/img/logo/solfacil-logo-dark.svg?inline',
      ][Number(this.$screen.lg)];
    },

    homeUrl() {
      return ['https://solfacil.com.br/home', '/loja'][
        Number(process.env.NODE_ENV === 'development')
      ];
    },
  },

  created() {
    if (!TokenService.getUser()) this.logout();
  },

  methods: {
    logout() {
      this.$keycloak.logout();
      TokenService.removeStorage();
      ApiService.removeHeader();
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  @apply bg-gray7 w-screen py-6 px-4 flex justify-between fixed top-0 z-50;

  .logo {
    @apply h-auto w-full;
    max-width: 26px;
  }

  nav {
    @apply flex justify-end items-center font-rubik;

    ul {
      @apply flex items-center;

      li {
        @apply mr-6;

        &:first-child {
          @apply hidden;
        }

        &:last-child {
          @apply mr-0;
        }

        span {
          @apply hidden;
        }
      }
      > .score-tag {
        @apply flex flex-row justify-around bg-rebrandingPrimary  rounded h-6;
        width: 94px;
        > .score-icon {
          @apply ml-2 mt-1;
        }
        > a {
          @apply cursor-pointer ml-2 mr-2 mt-1 text-white font-bold text-modest;
        }
      }
    }
  }

  @screen lg {
    @apply p-6;

    .logo {
      max-width: 134px;
    }

    nav {
      ul {
        li {
          @apply mr-0 ml-8;

          &:first-child {
            @apply block ml-0;
          }

          a {
            @apply flex items-center cursor-pointer;

            span {
              @apply block ml-2 text-white text-slight font-normal;
            }
          }

          > span {
            @apply block text-white text-modest font-medium;
          }
        }
      }
    }
  }
}
</style>
