<template>
  <div :id="id" class="sol-status-order" :class="{ 'opacity-0': load }">
    <Overlay id="overlay-status" :show="overlay">
      <VueSlickCarousel
        class="items"
        :class="{ 'is-admin': isAdmin, 'nav-arrow': slickOptions.draggable }"
        v-bind="slickOptions"
      >
        <template #prevArrow>
          <Icon type="prev" />
        </template>

        <div
          v-for="(history, index) in listHistory"
          :key="index"
          class="item-status"
          :class="[{ first: index === 0 }, history.class]"
        >
          <Input
            v-if="isAdmin && !history.insertedAt"
            :id="`expected-date-${history.status}`"
            v-model="history.value"
            type="text"
            placeholder="Data previsão"
            custom-mask="##/##/####"
          />

          <span class="line" />

          <p>
            {{ history.label }}

            <template v-if="isAdmin">
              <strong v-if="history.insertedAt">
                {{ history.insertedAt | date }}
              </strong>
              <span v-else class="space" />
            </template>

            <template v-else>
              <strong v-if="history.insertedAt || history.expectedDate">
                {{ getHistoryDate(history) }}
                <template v-if="history.expectedDate">
                  <br />(Previsão)
                </template>
              </strong>

              <strong v-else>{{ getLabel(history) }}</strong>
            </template>
          </p>
          <Button
            v-if="isStatusDelivering(history)"
            id="show-details-tracking"
            variant="secondary"
            class="text-details"
            @click="$emit('handleDetailsStatus')"
            >Detalhes</Button
          >
          <Button
            v-if="isStatusDelivered(history) && !orderRated"
            id="evaluate-tracking"
            variant="secondary"
            class="text-details"
            @click="$emit('handleEvaluateDelivery')"
            >Avaliar</Button
          >
        </div>

        <template #nextArrow>
          <Icon type="prev" class="last" />
        </template>
      </VueSlickCarousel>
    </Overlay>

    <hr v-if="isAdmin" class="mt-4 mb-6" />

    <section v-if="isPixPayment">
      <Alert icon id="alert-pix" variant="warning" label="Warning"
        >O prazo de pagamento da chave Pix é de 24 horas, após esse período a
        chave será automaticamente atualizada.
      </Alert>
      <div class="container-qr-code">
        <div v-if="isShowPixPayment">
          <div class="wrapper-qr-code">
            <p class="qr-code-title">Qr Code:</p>
            <QrCode :value="urlPayment" size="150" />
            <p class="qr-code-label">Copiar/colar:</p>
          </div>
          <div class="qr-code-url">
            <p class="qr-code-url-label">
              {{ urlPayment }}
            </p>
          </div>
          <CopyButton
            class="btn-copy-paste"
            :contentToCopy="urlPayment"
            :successMessage="'Chave PIX copiada com sucesso!'"
            label="Copiar Chave Pix"
          />
        </div>
        <button v-else @click="handlePixKey">Gerar chave pix</button>
      </div>
    </section>
    <form
      v-if="isAdmin"
      class="flex flex-wrap justify-end"
      @submit="postMessage"
    >
      <Textarea
        id="set-message-status"
        v-model="message"
        :invalid="$v.message.$error"
        msg-invalid="Campo obrigatório"
        label="Atualize a mensagem para o parceiro."
        class="w-full mb-4"
      />

      <Button id="save-message-status" variant="secondary" @click="postMessage">
        Salvar mensagem
      </Button>
    </form>

    <div v-else-if="lastStatus.message" class="box-message">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="lastStatus.message" class="text-left" />
      <Button
        v-if="showConfirmReceiptButton()"
        id="confirm-receipt-order"
        variant="secondary"
        @click="$emit('handleConfirmReceipt')"
      >
        Confirmar recebimento do pedido
        <span class="notification">1</span>
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  Button,
  Overlay,
  Textarea,
  Input,
  Alert,
} from '@solfacil/components-ui';
import { Icon } from '@components';
import CopyButton from '@components/shared/CopyButton';
import VueSlickCarousel from 'vue-slick-carousel';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { marketplaceStatus } from '@/mixins';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

import QrCode from 'qrcode.vue';

const LENGTH_STATUS_ORDER = 7;

export default {
  name: 'StatusOrder',

  components: {
    Alert,
    Button,
    CopyButton,
    Icon,
    Input,
    Overlay,
    QrCode,
    Textarea,
    VueSlickCarousel,
  },

  mixins: [validationMixin, marketplaceStatus],

  props: {
    //set id component, QA use validade
    id: {
      type: String,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },

    // set the admin view
    isAdmin: {
      type: Boolean,
      default: false,
    },

    currentStatus: {
      type: String,
      required: true,
    },

    // Array of order status
    historyOrder: {
      type: Array,
      required: true,
    },

    paymentMethod: {
      type: String,
      required: true,
    },

    urlPayment: {
      type: String,
      default: '',
    },
  },

  validations() {
    return {
      message: { required },
    };
  },

  data: (instance) => ({
    listHistory: instance.historyOrder,
    load: true,
    overlay: false,
    lastStatus: null,
    message: null,
    isShowPixPayment: false,
    slickOptions: {
      slidesToScroll: 1,
      slidesToShow: LENGTH_STATUS_ORDER,
      draggable: instance.historyOrder.length > LENGTH_STATUS_ORDER,
      centerMode: instance.historyOrder.length < LENGTH_STATUS_ORDER,
      dots: false,
      arrows: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 992,
          settings: 'unslick',
        },
      ],
    },
  }),

  computed: {
    ...mapGetters('order', ['ordersDelivered']),

    isPixPayment() {
      return (
        this.currentStatus === 'waiting_payment' && this.paymentMethod === 'PIX'
      );
    },
    orderRated() {
      return this.order.rating !== null;
    },
  },

  watch: {
    urlPayment(value) {
      this.setStateIsShowPixKey(Boolean(value));
    },
  },

  created() {
    this.checkLastStatus();

    this.listHistory.map(
      (item) => (item.value = this.formatDate(item.expectedDate)),
    );
  },

  mounted() {
    setTimeout(() => (this.load = false), 200);
  },

  methods: {
    getHistoryDate(history) {
      if (history.status !== 'waiting_payment') {
        return (
          this.formatDate(history.insertedAt) ||
          this.formatDate(history.expectedDate)
        );
      }
    },

    getLabel(history) {
      if (history.status !== 'waiting_payment') {
        return 'Aguarde a previsão';
      }
    },

    handlePixKey() {
      this.$emit('handleUrlPayment');
    },

    setStateIsShowPixKey(state) {
      this.isShowPixPayment = state;
    },

    isStatusDelivering(history) {
      return history.status === 'delivering' && Boolean(history.class);
    },
    isStatusDelivered(history) {
      return history.status === 'delivered' && Boolean(history.class);
    },

    checkLastStatus() {
      const status = this.historyOrder.filter(
        (history) => history.status === this.currentStatus,
      )[0];

      this.lastStatus = {
        message: [
          (status?.messages[0]?.content || '').replaceAll('\n', '<br />'),
          status?.messages[0]?.content || '',
        ][Number(this.isAdmin)],
        id: status?.id || null,
      };
    },

    formatDate(val) {
      if (!val) return '';
      return this.$root.$options.filters.date(val);
    },

    postMessage() {
      this.$v.$touch();

      !this.$v.$invalid && this.$emit('postMessage', this.message);
    },

    showConfirmReceiptButton() {
      const hasNotification = this.ordersDelivered.includes(this.order?.id);
      return hasNotification && this.currentStatus === 'delivered';
    },
  },
};
</script>

<style lang="scss" scoped>
.space {
  @apply block;
  height: 14px;
}

.items {
  @apply flex flex-col gap-6;

  .item-status {
    @apply flex gap-4 items-center relative pl-7;

    .line {
      @apply flex absolute items-center left-0 top-0 h-full;

      &:before {
        @apply bg-gray3 block w-4 h-4 rounded-full z-10;
        content: '';
      }

      &:after {
        @apply bg-gray3 block z-0;
        content: '';
        height: calc(100% + 16px);
        width: 2px;
        position: absolute;
        left: 7px;
        bottom: calc(100% - 20px);
      }
    }

    .sol-input {
      max-width: 122px;
    }

    &.in-progress {
      .line {
        &:before,
        &:after {
          @apply bg-blue3;
        }
      }
    }

    &.done {
      .line {
        &:before,
        &:after {
          @apply bg-green3;
        }
      }
    }

    &.removed {
      .line {
        &:before,
        &:after {
          @apply bg-red3;
        }
      }
    }

    &.review {
      .line {
        &:before,
        &:after {
          @apply bg-orangePrimary;
        }
      }
    }

    &.first {
      .line {
        &:after {
          @apply hidden;
        }
      }
    }

    p {
      @apply font-rubik text-small leading-tight flex flex-col;

      &:first-of-type {
        @apply m-0;
      }

      strong {
        @apply font-medium text-slight;
      }
    }
  }

  @screen lg {
    &.nav-arrow {
      @apply flex-row gap-4 w-full;
    }

    svg {
      @apply w-6 cursor-pointer duration-300 ease-linear transition-opacity;
      margin-top: -4px;

      &.slick-disabled {
        @apply opacity-25;
      }

      &.last {
        @apply transform rotate-180;
      }
    }

    .item-status {
      @apply flex flex-col items-center justify-end pl-0 #{!important};

      .line {
        @apply flex-col items-center relative h-auto w-full;

        &:after {
          @apply left-auto bottom-auto;
          height: 2px;
          width: 100%;
          position: absolute;
          top: 7px;
          right: 50%;
        }
      }

      p {
        @apply items-center text-modest;

        strong {
          @apply text-slight text-center;
        }
      }
    }
  }
}

.box-message {
  @apply border border-solid border-gray3 rounded p-6 mt-6;

  p {
    @apply font-rubik text-small leading-tight;
  }
  button {
    @apply flex justify-start mt-5;
  }

  .notification {
    @apply animate-ping w-4 h-4 mb-8 rounded-full text-center border border-solid bg-rebrandingPrimary font-rubik text-slight font-normal text-white absolute;
    margin-left: 275px;
    line-height: 15px;
  }

  @screen lg {
    @apply p-8 mt-8;
  }
}

.container-qr-code {
  @apply border-gray3 border rounded mt-8 p-4;
}

.wrapper-qr-code {
  @apply mt-3;

  width: 150px;
  margin: 0 auto;

  > .qr-code-title {
    @apply mb-3 text-grayPrimary;
  }

  > .qr-code-label {
    @apply mt-3 text-grayPrimary;
  }
}

.qr-code-url {
  @apply mt-4;

  > .qr-code-url-label {
    @apply break-words text-left;
    color: rgba(117, 117, 117, 0.64);
  }
}

.btn-copy-paste {
  @apply mt-5;
}

#alert-pix {
  @apply mt-12;
}
</style>
