<template>
  <transition name="fade" mode="out-in">
    <div v-show="showLoading" class="loader-overlay" />
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DefaultLoader',

  computed: mapGetters('loading', ['showLoading']),
};
</script>

<style lang="scss">
.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#fff, 0.9)
    url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
    center center;
  z-index: 99999;
}
</style>
