<template>
  <Alert
    :id="id"
    :icon="icon"
    :variant="variant"
    class="alert-not-result"
    :class="[{ 'text-center': center }, variant]"
    size="extra-large"
  >
    <slot />
  </Alert>
</template>

<script>
import { Alert } from '@solfacil/components-ui';

export default {
  name: 'AlertNotResult',

  components: {
    Alert,
  },

  props: {
    /** Specify a custom id */
    id: {
      type: String,
      required: true,
    },

    /** Specify whether the Alert should be a small variant */
    size: {
      default: 'small',
      type: String,
      validator: (value) =>
        ['small', 'medium', 'large', 'extra-large'].includes(
          value.toLowerCase(),
        ),
    },

    /** Specify whether the Icon should be enabled or not */
    icon: {
      type: Boolean,
      default: false,
    },

    /** Align text center */
    center: {
      type: Boolean,
      default: false,
    },

    /** Specify the kind of Alert */
    variant: {
      default: 'info-line',
      type: String,
      validator: (value) =>
        [
          'success',
          'error',
          'warning',
          'info',
          'info-neutral',
          'info-line',
        ].includes(value.toLowerCase()),
    },
  },
};
</script>

<style lang="scss">
.alert-not-result {
  @apply font-rubik text-small leading-tight;

  .txt {
    @apply flex-1;
  }

  &.info-line {
    @apply bg-white;
  }

  strong {
    @apply block text-modest font-medium;
  }

  a {
    @apply text-rebrandingPrimary underline;

    &:hover {
      @apply no-underline;
    }
  }
}
</style>
