const PHONE_LENGTH = 15;
const PHONE_NUMBER = '(##) ####-#####';
const PHONE_MOBILE = '(##) #####-####';

export const maskPhone = (phone) => {
  if (!phone) return PHONE_NUMBER;

  if (phone && phone.length == PHONE_LENGTH) {
    return PHONE_MOBILE;
  }
  return PHONE_NUMBER;
};
