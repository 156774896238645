import { TITLE_TABLE } from '@/helper/constants.helper';

export default {
  data() {
    return {
      titleTable: TITLE_TABLE,
    };
  },
  methods: {
    hasOwnLogistic(products) {
      if (products['node']) {
        return this.hasSomeOwnLogistic(products['node']);
      }
      return this.hasSomeOwnLogistic(products.attributes);
    },

    hasSomeOwnLogistic(kits) {
      return kits.some((item) => item.key === 'own_logistic' && item.value);
    },

    formattedDateShipping(minDate, maxDate) {
      if (!minDate && !maxDate) return;

      return `${minDate} - ${maxDate} dias úteis`;
    },

    getFormattedProducts(products) {
      return products.reduce((acc, item) => {
        if (item) {
          return [...acc, { node: item }];
        }

        return acc;
      }, []);
    },
  },
};
