/**
 * Hamburger
 */
const state = () => ({
  open: false,
});

const actions = {
  toggleHamburger({ commit, state }) {
    commit('TOGGLE');

    state.open
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  },
};

const mutations = {
  TOGGLE: (state) => (state.open = !state.open),
};

const getters = {
  statusHamburger: (state) => state.open,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
