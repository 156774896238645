import { TokenService } from '@/services/';

const state = {
  authenticating: false,
  accessToken: TokenService.getToken(),
  user: null,
};

const actions = {
  setUser({ commit }, { user, authenticating }) {
    commit('SET_USER', { user, authenticating });
  },
};

const mutations = {
  SET_USER(state, { user, authenticating }) {
    state.user = user;
    state.authenticating = authenticating;
  },
};

const getters = {
  loggedIn: (state) => {
    return state.accessToken ? true : false;
  },

  authenticating: (state) => {
    return state.authenticating;
  },

  user: (state) => {
    return state.user;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
