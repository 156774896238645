<template>
  <div class="tooltip bottom">
    <span class="tooltip-title" @click="$emit('click')"
      >{{ title }} | Alterar</span
    >
    <div class="tooltip-container">
      <p>
        Para melhores ofertas de kits na sua região, identificamos que você está
        em <span>{{ location }} e região</span>
      </p>
      <Button
        id="update-address"
        label="Alterar"
        variant="exception"
        size="medium"
        tag="button"
        @click="$emit('click')"
      >
        Alterar
      </Button>
    </div>
  </div>
</template>

<script>
import { Button } from '@solfacil/components-ui';

export default {
  name: 'MarketplacePopOver',
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    location: {
      type: String,
      default: 'São Paulo',
    },
  },
};
</script>

<style lang="scss" scoped>
#update-address {
  @apply ml-3;

  span {
    @apply text-rebrandingPrimary;
  }
}
.tooltip {
  pointer-events: none;

  .tooltip-title {
    @apply font-bold;
  }

  @screen lg {
    @apply relative inline-block cursor-pointer;
    pointer-events: visible;
  }

  .tooltip-container {
    @apply bg-grayPrimary text-white absolute  rounded p-4 opacity-0;
    visibility: hidden;
    width: 380px;
    z-index: 1;
    transition: visibility 0.3s;

    &::after {
      @apply absolute;
      content: '';
      border: 5px solid transparent;
    }
  }
}

.bottom {
  @apply underline text-green3 font-bold;

  .tooltip-container {
    @apply mt-3;
    top: 100%;
    right: 0;
    left: 0;

    &::after {
      @apply cursor-pointer;
      bottom: 100%;
      left: 15%;
      border-color: transparent transparent #666666 transparent;
    }

    p {
      @apply mb-5  font-normal text-modest text-left;

      span {
        @apply text-white;
      }
    }
  }
}

.tooltip:hover .tooltip-container {
  @apply cursor-pointer opacity-100;
  visibility: visible;
}

#update-address {
  @apply float-right;

  &:hover {
    @apply text-white;
  }
}
</style>
