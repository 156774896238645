import { ApiService, ResultError } from '@/services';

export default {
  async getMe() {
    const payload = {
      query: `mutation {
                  migrate {
                      external_id
                      email
                      name
                      id
                      phone_number
                      franchise
                      profile
                      customer {
                          level
                          company_name
                          address {
                              city
                              state
                          }
                          partner {
                            cnpj
                          }
                      }
                  }
              }`,
    };

    try {
      const response = await ApiService.post(payload);

      const result = response.data.data.migrate;

      if (result?.franchise) {
        setTimeout(() => {
          window.location.href = 'https://solfacil.com.br/home';
        }, 2000);

        ResultError({
          message: 'Você não tem permissão para acessar este serviço',
        });
      }

      return Promise.resolve(result);
    } catch (error) {
      ResultError(error);
    }
  },
};
