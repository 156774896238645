import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routers';
import store from '@/store';
import { TokenService } from '@/services';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const originalPush = router.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  /**
   * Ensuring polymorphism when using with callbacks (Ex: onComplete, onAbort).
   */
  if (onResolve || onReject) {
    return originalPush.call(router, location, onResolve, onReject);
  }
  /**
   * Ensuring polymorphism when using with promise (Ex: then() or catch()) and
   * handling the exception in case of redirect cancellation.
   */
  return originalPush.call(router, location).catch(() => {});
};

const nextFactory = (context, middleware, index) => {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
};

router.beforeEach((to, from, next) => {
  if (to.name === from.name) return;

  const payload = {
    from: from.path,
    to: to.path,
  };
  const isPublic = to.matched.some((record) => record.meta.public);
  const partnerOnly = to.matched.some((record) => record.meta.partnerOnly);
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut,
  );
  const loggedIn = store.getters['auth/authenticating'];
  const hideMyOrders = ['admin', 'avulso', 'fornecedor'].includes(
    TokenService.getUser()?.profile,
  );

  // send tracking page view
  Vue.prototype.sendTracking('navigate', 'ecommerce', payload);

  // close menu mobile
  if (store.getters['hamburger/statusHamburger']) {
    store.commit('hamburger/TOGGLE');
  }

  // Title page
  document.title = to.meta.title
    ? to.meta.title + ' | ' + process.env.VUE_APP_TITLE
    : process.env.VUE_APP_TITLE;

  // Class page
  document.body.className = to.name;

  if (to.name !== 'login' && !isPublic && !loggedIn) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
    });

    return;
  }

  if (partnerOnly && hideMyOrders) {
    next('/loja');
    return;
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    next('/loja');

    return;
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  let flags = JSON.parse(localStorage.getItem('flags'));

  if (flags) {
    const flagName = Object.keys(flags).find((flag) => to.path.includes(flag));

    if (flags[flagName] && !flags[flagName]?.current) {
      to.path !== '/loja' && next('/loja');

      return;
    }
  }

  next();
});

export default router;
