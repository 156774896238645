import { render, staticRenderFns } from "./ModalConfirmReceiptOrder.vue?vue&type=template&id=4b4511b9&scoped=true&"
import script from "./ModalConfirmReceiptOrder.vue?vue&type=script&lang=js&"
export * from "./ModalConfirmReceiptOrder.vue?vue&type=script&lang=js&"
import style0 from "./ModalConfirmReceiptOrder.vue?vue&type=style&index=0&id=4b4511b9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b4511b9",
  null
  
)

export default component.exports