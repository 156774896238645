export default {
  development: {
    apiURL: '//localhost:4004',
    legacyURL: '//hmle-legacy.solfacil.com.br/',
  },
  staging: {
    apiURL: '//stg-marketplace-api.solfacil.com.br',
    legacyURL: '//hmle-legacy.solfacil.com.br/',
  },
  hmla: {
    apiURL: '//hmla-marketplace-api.solfacil.com.br',
    legacyURL: '//hmla-legacy.solfacil.com.br/',
  },
  hmle: {
    apiURL: '//hmle-marketplace-api.solfacil.com.br',
    legacyURL: '//hmle-legacy.solfacil.com.br/',
  },
  hmle2: {
    apiURL: '//hmle2-marketplace-api.solfacil.com.br',
    legacyURL: '//hmle2-legacy.solfacil.com.br/',
  },
  hmli: {
    apiURL: '//hmli-marketplace-api.solfacil.com.br',
    legacyURL: '//hmli-legacy.solfacil.com.br/',
  },
  hmli2: {
    apiURL: '//hmli2-marketplace-api.solfacil.com.br',
    legacyURL: '//hmli2-legacy.solfacil.com.br/',
  },
  hmlg: {
    apiURL: '//hmlg-marketplace-api.solfacil.com.br',
    legacyURL: '//hmlg-legacy.solfacil.com.br/',
  },
  hmlg2: {
    apiURL: '//hmlg2-marketplace-api.solfacil.com.br',
    legacyURL: '//hmlg2-legacy.solfacil.com.br/',
  },
  production: {
    apiURL: '//marketplace-api.solfacil.com.br',
    legacyURL: '//solfacil.com.br/',
  },
};
