export default {
  computed: {
    delivery: () => {
      return (attr) => {
        const filter = attr.filter(
          (el) => el.key === 'availability_deadline' && el,
        );

        if (filter[0]?.value === 'Pedido Express*') {
          return { delivery: 'express', value: filter[0].value };
        }

        return { delivery: 'fast', value: 'Pronta Entrega' };
      };
    },

    inverterPower: () => {
      return (kit) => {
        let connection;

        if (!kit.kitInverters.length) return connection;

        kit.kitInverters[0].item.attributes.map((item) => {
          if (item.key === 'connection') connection = item.value;
        });

        return `${(kit.kitInverters[0].item.power / 1000).toFixed(
          1,
        )} kW | ${connection}`;
      };
    },

    inverterBrand: () => {
      return (kit) => {
        let brand;

        kit.kitInverters.length &&
          kit.kitInverters[0].item.attributes.map((item) => {
            if (item.key === 'brand') brand = item.value;
          });

        return brand;
      };
    },

    modulePower: () => {
      return (kit) => {
        let kwp = kit.power / 1000;
        kwp = `${
          [kwp, kwp.toFixed(2)][Number(kwp.toString().indexOf('.') !== -1)]
        } kWp`;

        if (!kit.kitModules.length) return kwp;

        const module = `(${kit.kitModules[0].amount} x ${kit.kitModules[0].item.power}Wp)`;

        return `${kwp} ${module}`;
      };
    },

    moduleBrand: () => {
      return (kit) => {
        let brand;

        kit.kitModules.length &&
          kit.kitModules[0].item.attributes.map((item) => {
            if (item.key === 'brand') brand = item.value;
          });

        return brand;
      };
    },
  },
};
