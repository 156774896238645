<template>
  <footer :id="id" :class="['footer-cart', { show, cart }]">
    <div :class="{ container: cart }">
      <p>
        <span>
          Qtde total: <strong>{{ resume.amount }}</strong>
        </span>
        <span class="p-2">|</span>
        <span>
          Preço total:<strong>{{ resume.total | currencyInteger }}</strong>
        </span>
      </p>

      <Button id="show-cart" class="btn w-full lg:w-auto" @click="handleButton">
        {{ buttonLabel }}
      </Button>
    </div>
  </footer>
</template>

<script>
import { Button } from '@solfacil/components-ui';
import { mapGetters } from 'vuex';
export default {
  name: 'CartFooter',

  components: {
    Button,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    resume: {
      required: true,
      type: Object,
      validator: function (obj) {
        return 'amount' in obj && 'total' in obj;
      },
    },

    buttonLabel: {
      type: String,
      default: 'Ver carrinho',
    },

    cart: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      show: false,
    };
  },

  computed: {
    ...mapGetters('marketplace', ['listProductsCart']),
  },

  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 100);
  },

  methods: {
    handleButton() {
      this.$emit('handleButton');
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  @apply fixed right-0 border-t p-2 border-solid border-gray3 bg-gray1 items-center opacity-0 invisible z-20 duration-300 ease-in-out transition-all;
  width: calc(100% - 260px);

  > div {
    @screen lg {
      @apply flex justify-end gap-4;
    }
  }

  &.cart {
    @apply px-0 w-full bg-white;
  }

  &.show {
    @apply visible opacity-100 bottom-0 w-full;

    @screen lg {
      @apply visible opacity-100 bottom-0;
    }
  }

  p {
    @apply items-center mb-3 text-center;

    @screen lg {
      @apply flex items-center gap-4 mb-0;
    }
  }
}
</style>
