<template>
  <button @click="copyToClipboard">{{ label }}</button>
</template>

<script>
export default {
  name: 'CopyButton',
  props: {
    contentToCopy: {
      type: String,
      default: 'No Copy Content',
    },
    label: {
      type: String,
      default: 'Copy and paste',
    },
    successMessage: {
      type: String,
      default: 'Success Message',
    },
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.contentToCopy)
        .then(() => {
          this.$toaster.success(this.successMessage, {
            id: `success-copy-paste`,
          });
        })
        .catch((error) => this.$emit('copy-failed', error));
    },
  },
};
</script>
