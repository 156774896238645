<template>
  <div id="app">
    <Loading />

    <router-view />
  </div>
</template>

<script>
import Loading from '@components/core/Loading';

export default {
  name: 'App',

  components: {
    Loading,
  },
};
</script>
