import axios from 'axios';
import store from '@/store';
import { TokenService } from './storage';
import { executeLogout } from '../main';
import Vue from 'vue';

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
    this.type = 'error';
  }
}

const http = axios.create();
const socket = {};

const Notify = (e, $this, id = Math.random().toString(36).substr(2, 9)) => {
  if (e.type) {
    console.error(`>>>> ${e.type}`, e.message);
    const msg = `Desculpe, ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde.`;
    $this &&
      $this._vm.$toaster[e.type](msg, {
        id: `${e.type}-${id}`,
      });
    return;
  }

  $this &&
    $this._vm.$toaster.success(e.message, {
      id: `success-${id}`,
    });
};

const refreshToken = async () => {
  await Vue.prototype.$keycloak.updateToken(-1);
  TokenService.saveToken(Vue.$keycloak.token);
};

const retryRequest = async (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401) {
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      await refreshToken();
      return http(originalRequest);
    }

    return executeLogout();
  }

  return Promise.reject(error);
};

const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  init(baseURL) {
    socket.baseURL = `${process.env.VUE_APP_PROTOCOL}:${baseURL}`;
    http.defaults.baseURL = baseURL;
    http.params = {};
    http.interceptors.response.use((response) => {
      return response;
    }, retryRequest);
  },

  setHeader() {
    http.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    http.defaults.headers.common = {};
  },

  post(data, isLoading) {
    return http.post('/graphql', data, { isLoading });
  },

  postMkt(resource, data, isLoading) {
    return http.post(`${http.defaults.baseURL}/${resource}`, data, {
      isLoading,
    });
  },
};

const ResultError = (error) => {
  throw new AuthenticationError(
    error.message ? 200 : 503,
    error.message ? error.message : 'Network Error',
  );
};

http.interceptors.request.use(
  (config) => {
    // start loading
    if (config.isLoading) store.dispatch('loading/showLoad');

    config.headers['Authorization'] = `Bearer ${TokenService.getToken()}`;

    return config;
  },
  (error) => {
    // stop loading
    store.dispatch('loading/hideLoad');

    return Promise.reject({ ...error, type: 'error' });
  },
);

http.interceptors.response.use(
  (response) => {
    // stop loading
    store.dispatch('loading/hideLoad');

    return response;
  },
  (e) => {
    // stop loading
    store.dispatch('loading/hideLoad');

    return Promise.reject({ ...e, type: 'error' });
  },
);

export default { http };

export {
  http,
  socket,
  Notify,
  TokenService,
  ApiService,
  AuthenticationError,
  ResultError,
};
