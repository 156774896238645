import Vue from 'vue';
import { DEFAULT_API } from '@utils';

export default () => {
  const host = window.location.host;
  const urlParams = new URLSearchParams(
    decodeURIComponent(window.location.search),
  );
  const env = urlParams.get('env')
    ? urlParams.get('env')
    : localStorage.getItem('apiServer');

  // API load local
  if (process.env.NODE_ENV === 'development') {
    Vue.prototype.$api = DEFAULT_API[process.env.NODE_ENV];
    Vue.prototype.$env = 'development';
    return;
  }

  // API load Production
  if (host === 'app.solfacil.com.br') {
    Vue.prototype.$api = DEFAULT_API['production'];
    Vue.prototype.$env = 'production';
    return;
  }

  // API load for Query parameters
  if (env && DEFAULT_API[env]) {
    Vue.prototype.$api = DEFAULT_API[env];
    Vue.prototype.$env = env;
    localStorage.setItem('apiServer', env);
    return;
  }

  // Fallback, default load API staging
  Vue.prototype.$env = 'staging';
  Vue.prototype.$api = DEFAULT_API['staging'];
};
