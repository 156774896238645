<template>
  <SortedTable
    id="list-kits"
    :fields="titleTable"
    last-right
    class="sorted-ui"
    orderBy=""
  >
    <template #thead-th>
      <th></th>
    </template>

    <template #tbody>
      <tr
        v-for="(product, index) in products"
        :key="product.node.sku"
        role="row"
      >
        <td role="cell">
          <span class="check-price">
            <Checkbox
              v-if="isSelectKit"
              :id="`check-${product.node.sku}`"
              name="selected"
              :value="handleIsSelected(product)"
              class="checkbox"
              @input="$emit('handleClickSelect', product, index)"
            />

            <div class="info-badge">
              <div class="container-badges">
                <Badge
                  v-for="item in getInfoBadge(product.node.attributes)"
                  :key="item.label"
                  :content="item.label"
                  :variant="item.variant"
                />
              </div>
            </div>

            <span
              v-if="product.node.discounted_price"
              @click="$emit('handleClickSelect', product, index)"
            >
              <span class="price-in">
                {{ product.node.price | currencyInteger }}
              </span>

              <span
                class="price-per"
                :class="
                  product.node.price > product.node.discounted_price
                    ? 'down'
                    : 'up'
                "
              >
                {{ product.node.discounted_price | currencyInteger }}
                <IconArrow class="icon" />
              </span>
            </span>

            <span v-else @click="$emit('handleClickSelect', product, index)">
              {{ product.node.price | currencyInteger }}
            </span>
          </span>
        </td>

        <td role="cell">
          {{ modulePower(product.node) }}
          <small>{{ moduleBrand(product.node) }}</small>
        </td>

        <td role="cell">
          {{ inverterPower(product.node) }}
          <small>{{ inverterBrand(product.node) }}</small>
        </td>

        <td role="cell">
          {{
            product.node.roof_type ||
            product.node.roofType | capitalizeFirstLetter
          }}
        </td>

        <td role="cell" class="shipping">
          <div class="shipping__label">
            <span v-if="hasOwnLogistic(product.node)" class="flex">
              <img
                src="https://cdn.solfacil.com.br/frontoffice-loja/icons/shipping-solfacil-green.svg"
                alt="Caminhão solfacil envios"
                class="shipping-icon"
              />
            </span>
          </div>
          <span class="shipping__price">{{
            formattedDateShipping(
              product.node.shipping?.min_date_limit,
              product.node.shipping?.max_date_limit,
            )
          }}</span>
        </td>

        <td v-if="isShowCart || isSelectKit">
          <InputMinusPlus
            :id="index ? `kit-qtde-${product.node.sku}` : 'kit-qtde-tour'"
            :value="product.node.amount"
            :min="minCartProduct"
            :max="99"
            small
            @input="$emit('handleUpdateAmount', $event, product)"
          />
        </td>

        <td v-else role="cell" @click.stop.prevent="">
          <span :class="{ qtde: isOrderList }">{{ product.node.amount }}</span>
        </td>

        <td role="cell" @click.stop.prevent="">
          <HomeDetails
            v-if="!isShowCart"
            @onDetailsClick="$emit('handleProductDetail', product)"
          />
          <NavActions
            :id="index ? `options-${product.node.sku}` : 'option-tour'"
            position="lefttop"
            v-if="isShowCart"
          >
            <span
              class="icons-popover"
              @click="$emit('handleProductDetail', product)"
            >
              <Icon type="detail" />
              Detalhes do Kit
            </span>

            <span
              v-if="isShowCart"
              class="icons-popover"
              @click="$emit('removeProduct', product)"
            >
              <Icon type="remove" />
              Remover Kit
            </span>
          </NavActions>
        </td>
      </tr>
    </template>
  </SortedTable>
</template>

<script>
import attributesKit from '@/mixins/attributesKit';
import IconArrow from '@img/icons/icon-arrow-down.svg';
import { HomeDetails, Icon, NavActions } from '@components';
import formattedDateShipping from '@/mixins/formattedDataShipping.mixin';
import { Checkbox, InputMinusPlus, SortedTable } from '@solfacil/components-ui';
import Badge from '@components/shared/MarketplaceBadge';

const MIN_PRODUCT_IN_CART = [1, 0];

export default {
  name: 'TableMarketplace',
  components: {
    InputMinusPlus,
    SortedTable,
    NavActions,
    IconArrow,
    Checkbox,
    Icon,
    Badge,
    HomeDetails,
  },
  mixins: [attributesKit, formattedDateShipping],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    selectedProducts: {
      type: Array,
      default: () => [],
    },
    isSelectKit: {
      type: Boolean,
      default: false,
    },
    isShowCart: {
      type: Boolean,
      default: false,
    },
    isOrderList: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    minCartProduct() {
      return MIN_PRODUCT_IN_CART[Number(this.isSelectKit)];
    },
  },

  methods: {
    handleIsSelected(product) {
      return this.selectedProducts.some((prod) => {
        return prod.node.sku === product.node.sku;
      });
    },
    getInfoBadge(attributes) {
      const ATTRIBUTES_PROMOTIONS = ['insurance_installation', 'black_friday'];

      return attributes.reduce((acc, item) => {
        if (ATTRIBUTES_PROMOTIONS.includes(item.key) && item.value) {
          const BADGES_ATTRS = this.getFormattedForBadge();

          return [
            ...acc,
            {
              ...BADGES_ATTRS[item.key],
            },
          ];
        }

        return [...acc];
      }, []);
    },

    getFormattedForBadge() {
      return {
        insurance_installation: {
          label: 'Seguro Instalação',
          variant: 'info',
        },
        insured_price: {
          label: 'Preço garantido',
          variant: 'done',
        },
        black_friday: {
          label: 'Solar Friday',
          variant: 'exception',
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.info-badge {
  @apply absolute -ml-7;
  margin-top: -100px;

  @screen lg {
    @apply -mt-12;
  }

  .container-badges {
    @apply flex gap-2;
  }
}

table tr {
  position: inherit;
}

table tr td {
  @screen lg {
    @apply pt-5 pb-7;
  }
}

.sorted-ui {
  ::v-deep tr[role='row'] {
    th {
      @apply bg-white;
      &:first-child {
        @apply p-4 pl-9;
      }
      &:nth-child(n + 6) {
        @apply p-4 pl-9;
      }
      strong {
        @apply cursor-auto;
        i {
          @apply hidden;
        }
      }
    }
  }
}

.sort-table {
  .shipping {
    &__label {
      @apply text-green3 font-bold;
    }

    &__price {
      @apply text-grayPrimary;
    }
  }

  small {
    @apply text-slight leading-tight block;
  }

  .qtde {
    @apply border border-solid border-gray2 rounded inline-flex items-center justify-center h-7 w-8 text-small font-bold;
  }
  .check-price {
    @apply flex items-center text-modest font-bold leading-tight;

    .price-in {
      @apply font-rubik font-medium text-slight block line-through;
    }

    .price-per {
      @apply flex items-center;

      &.down {
        @apply text-green2;
      }

      &.up {
        @apply text-red2;

        .icon {
          transform: rotate(180deg);
        }
      }

      .icon path {
        @apply stroke-current;
      }
    }
  }

  strong {
    @apply text-modest leading-tight font-bold;
  }

  > span {
    @apply block;
  }

  small {
    @apply block text-slight leading-normal;
  }
}
</style>
