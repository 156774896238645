import Vue from 'vue';

export const environments = () => {
  const host = window.location.host;

  const urlParams = new URLSearchParams(
    decodeURIComponent(window.location.search),
  );

  // ENV load Production
  if (host === 'app.solfacil.com.br') {
    Vue.prototype.$launchdarklyId = process.env.VUE_APP_LAUNCHDARKLY_PROD_ID;
    Vue.prototype.$mixpanelId = process.env.VUE_APP_MIXPANEL_PROD;

    return;
  }

  // ENV load HML
  if (urlParams.get('env')) {
    Vue.prototype.$launchdarklyId = process.env.VUE_APP_LAUNCHDARKLY_HML_ID;
    Vue.prototype.$mixpanelId = process.env.VUE_APP_MIXPANEL_DEV;

    return;
  }
  // Fallback, default load ENV development
  Vue.prototype.$launchdarklyId = process.env.VUE_APP_LAUNCHDARKLY_DEV_ID;
  Vue.prototype.$mixpanelId = process.env.VUE_APP_MIXPANEL_DEV;
};
