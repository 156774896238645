<template>
  <div class="file-uploader">
    <label>{{ label }}</label>

    <div class="content-files" :data-testid="testId" :class="{ invalid }">
      <FilePreview
        v-model="previewFiles"
        :placeholder="placeholder"
        :label="label"
        @input="setFiles"
      />

      <span
        v-if="canAddMoreFile"
        class="inline-block cursor-pointer"
        @click="handleFile"
      >
        <Icon type="file-upload" />
      </span>

      <input
        :id="id"
        :ref="id"
        type="file"
        :multiple="multiple"
        class="hidden"
        :accept="accept"
        @change="onFileChange($event)"
      />
    </div>

    <small v-if="invalid && msgInvalid" class="msg-error">
      {{ msgInvalid }}
    </small>
  </div>
</template>

<script>
import Icon from './../Icon';
import FilePreview from './FilePreview';

export default {
  name: 'FileUploader',

  components: {
    Icon,
    FilePreview,
  },

  props: {
    accept: {
      type: String,
      default: 'application/pdf, image/* ',
    },

    id: {
      type: [String, Number],
      require: true,
      default: null,
    },

    testId: {
      type: String,
      default: 'test-id',
    },

    label: {
      type: String,
      required: true,
    },

    maxFiles: {
      type: Number,
      default: 1,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'Adicione um arquivo',
    },

    value: {
      type: Array,
      default: () => [],
    },

    msgInvalid: {
      type: String,
      default: null,
    },

    invalid: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      canAddMoreFile: true,
      files: [],
      previewFiles: [],
    };
  },

  computed: {
    // Return max file to upload
    getMaxFilesNum: function () {
      if (this.maxFiles && this.maxFiles > 0) {
        return parseInt(this.maxFiles);
      }

      if (!this.multiple) {
        return 1;
      }

      return 100;
    },
  },

  watch: {
    value() {
      this.previewFiles = this.value;
    },

    previewFiles() {
      this.canAddMoreFile = this.previewFiles.length < this.getMaxFilesNum;
    },
  },

  created() {
    this.previewFiles = this.value;
  },

  methods: {
    handleFile() {
      this.$refs[this.id].click();
    },

    // Open File chooser on click event on form
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }

      this.clearFileInput();
      this.setFiles();
    },

    // Set files selected
    setFiles() {
      this.previewFiles.push.apply(this.previewFiles, this.files);
      this.$emit('input', this.previewFiles);
      this.files = [];
    },

    // Clear file input data.
    clearFileInput() {
      const input = this.$refs[this.id];
      input.type = 'text';
      input.type = 'file';
    },

    /**
     * Removes a select file the user has uploaded
     * @param key  Integer  Index of preview array.
     */
    removeFile(key) {
      if (this.deleteCallBack !== undefined) {
        this.deleteCallBack(this.previewFiles[key].id);
      }
      this.previewFiles.splice(key, 1);
      this.$emit('input', this.previewFiles);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-uploader {
  .content-files {
    @apply rounded p-4 border border-solid border-gray3 flex gap-4 items-center flex-wrap;

    &.invalid {
      @apply border-red2;
    }
  }
}
</style>
