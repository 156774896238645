import mixpanel from 'mixpanel-browser';

export default {
  install(Vue) {
    Vue.prototype.sendTracking = (event, category, value) => {
      const urlParams = new URLSearchParams(window.location.search);
      const campaign = urlParams.get('campaign');
      const oldCampaign = localStorage.getItem('campaign');

      if (campaign === null && oldCampaign === null)
        localStorage.setItem('campaign', 'direct');
      else if (campaign !== null) localStorage.setItem('campaign', campaign);

      const user = JSON.parse(localStorage.getItem('user'));

      if (user)
        value = {
          user_id: user.id,
          email: user.email,
          name: user.name,
          campaign: localStorage.getItem('campaign'),
          ...value,
        };

      if (value && !value.email?.includes('@solfacil.com.br')) {
        window.gtag('event', event, {
          event_category: category,
          value: value,
        });

        mixpanel.init(Vue.prototype.$mixpanelId, {
          api_host: 'https://api.mixpanel.com',
        });

        mixpanel.track(event, value);

        if (typeof window.dataLayer?.push === 'function')
          window.dataLayer.push({
            event,
            event_category: category,
            value,
          });
      }
    };
  },
};
