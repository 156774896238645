import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import router from '@/router';

export default () => {
  process.env.NODE_ENV === 'production' &&
    window.location.host === 'app.solfacil.com.br' &&
    Sentry.init({
      Vue,
      dsn: 'https://f25c4deaf6ac491f883f261d64cb4237@o302946.ingest.sentry.io/6134302',
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['app.solfacil.com.br', /^\//],
        }),
      ],
      tracesSampleRate: 1.0,
      environment: process.env.NODE_ENV,
      release: process.env.RELEASE || '1.0',
    });
};
