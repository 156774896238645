<template>
  <Popover :id="id" :position="position">
    <img
      src="@img/icons/icon-options.svg?inline"
      class="ico-option"
      alt="Opções"
    />

    <template slot="popover">
      <slot></slot>
    </template>
  </Popover>
</template>

<script>
import { Popover } from '@solfacil/components-ui';

export default {
  name: 'NavActions',

  components: {
    Popover,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    position: {
      default: 'lefttop',
      type: String,
      validator: (value) =>
        [
          'top',
          'topleft',
          'topright',
          'right',
          'righttop',
          'rightbottom',
          'bottom',
          'bottomleft',
          'bottomright',
          'left',
          'lefttop',
          'leftbottom',
        ].includes(value.toLowerCase()),
    },
  },
};
</script>

<style lang="scss" scoped>
.ico-option {
  @apply w-8 h-8 rounded-full border-2 border-solid border-gray3 duration-200 ease-linear transition-all;
  padding: 10px;

  &:hover {
    @apply border-gray4;
  }
}
</style>

<style lang="scss">
.sol-popover {
  @apply w-8 z-20 #{!important};

  .popover {
    min-width: inherit !important;

    .content-popover {
      width: max-content;
    }
  }
}

.icons-popover {
  @apply flex items-center w-full py-2 border-t border-solid border-gray3 cursor-pointer whitespace-no-wrap;

  &:first-child {
    @apply border-t-0;
  }
}
</style>
