<template>
  <div class="badge-info" :class="variant">{{ content }}</div>
</template>

<script>
export default {
  name: 'MarketplaceBadge',

  props: {
    content: {
      type: String,
      default: 'Badge',
    },
    variant: {
      type: String,
      default: 'done',
    },
  },
};
</script>

<style lang="scss" scoped>
$badge-colors: (
  'done': #008859,
  'info': #176ec4,
  'exception': #000000,
);

.badge-info {
  @apply w-15 font-rubik text-center uppercase p-1 text-white;
  font-size: 10px;
  border-radius: 24px;

  @each $color, $i in $badge-colors {
    &.#{$color} {
      background: $i;
    }
  }
}
</style>
