<template>
  <Modal
    id="product-detail"
    v-model="showModal"
    title="Detalhes do kit solar"
    class="product-detail"
    size-lg
    button-close="secondary"
    @closeModal="closeModal"
  >
    <DatasheetButtons :kit="kit" @onSendTrack="dataSheetSendTrack($event)" />
    <List id="detail-list" :items="detailsList" />
    <div v-if="descriptionModal.length" class="description">
      <h4>Descrição</h4>

      <ul>
        <template v-for="(desc, index) in descriptionModal">
          <li v-if="desc.length && !desc.includes('DATASHEET')" :key="index">
            {{ desc }}
          </li>
        </template>
      </ul>
    </div>

    <template #footer>
      <Button v-if="!isSelected" id="select-product" @click="selectProduct">
        Selecionar
      </Button>

      <strong class="price">
        Preço: R$
        <span>{{
          data.node.discounted_price ||
          data.node.price | currencyInteger('noSign')
        }}</span>
      </strong>
    </template>
  </Modal>
</template>

<script>
import { Button, List, Modal } from '@solfacil/components-ui';
import { DatasheetButtons } from '@components';

export default {
  name: 'ModalDetailKit',

  components: {
    Button,
    DatasheetButtons,
    List,
    Modal,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: [Object, Array],
      require: true,
      default: () => {},
    },
  },

  data() {
    return {
      showModal: false,
      kit: {
        modulesAttr: [],
        inverterAttr: [],
      },
      detailsList: null,
    };
  },

  computed: {
    isSelected() {
      return !!this.data.node?.amount;
    },

    descriptionModal() {
      if (this.data.node) {
        const { description } = this.data.node;
        return description
          ? description.replaceAll('\n\r\n', '\n').split('\n')
          : [];
      }
      return [];
    },
  },

  created() {
    this.converter();
  },

  mounted() {
    this.showModal = this.value;
  },

  methods: {
    converter() {
      const { kitModules, kitInverters, roofType, attributes } = this.data.node;

      let modulesAttr = this.createModulesAndInvertersAttributes(kitModules);
      let inverterAttr = this.createModulesAndInvertersAttributes(kitInverters);
      const attr = attributes.reduce((acc, { key, value }) => {
        return (acc = { ...acc, [key]: value });
      }, {});

      this.kit.modulesAttr = modulesAttr;
      this.kit.inverterAttr = inverterAttr;

      this.setDetailsList(
        kitModules,
        modulesAttr,
        kitInverters,
        inverterAttr,
        attr,
        roofType,
      );
    },

    createModulesAndInvertersAttributes(infoArray) {
      let attributesArray = [];
      for (let info of infoArray) {
        attributesArray = [
          ...attributesArray,
          info.item.attributes.reduce((acc, { key, value }) => {
            return { ...acc, [key]: value };
          }, {}),
        ];
      }

      return attributesArray;
    },

    getModulesInvertersPropValues(attrArray, prop) {
      const clonedArray = structuredClone(attrArray);

      if (clonedArray.length > 1) {
        let concatAtrr = '';
        attrArray.forEach((attr) => (concatAtrr += `${attr[prop]}, `));
        return concatAtrr.slice(0, -2);
      }

      if (attrArray[0][prop]) return attrArray[0][prop];

      return '';
    },

    getkitInvertersItemValues(kitArr, props) {
      const clonedArray = structuredClone(kitArr);
      const type = clonedArray[0].item.type;

      if (clonedArray.length > 1) {
        let concatItemValues = '';
        kitArr.forEach(
          (kitInverter) =>
            (concatItemValues += `${this.formatInverterPotency(
              kitInverter.item[props],
              props,
              type,
            )}, `),
        );
        return concatItemValues.slice(0, -2);
      }

      return this.formatInverterPotency(kitArr[0].item[props], props, type);
    },

    formatInverterPotency(value, prop, type) {
      if (prop.includes('power') && type.includes('INVERTER')) {
        return (value / 1000).toFixed(2);
      }
      return value;
    },

    setDetailsList(
      kitModules,
      modulesAttr,
      kitInverters,
      inverterAttr,
      attributes,
      roofType,
    ) {
      this.detailsList = [
        {
          title: 'Nº de placas',
          description:
            this.getModulesInvertersPropValues(kitModules, 'amount') || '-',
        },
        {
          title: 'Marca módulos',
          description:
            this.getModulesInvertersPropValues(modulesAttr, 'brand') || '-',
        },
        {
          title: 'Modelo módulos',
          description: this.getModulesInvertersPropValues(modulesAttr, 'model'),
        },
        {
          title: 'Potência módulos',
          description:
            `${this.getkitInvertersItemValues(kitModules, 'power')} W` || '-',
        },
        {
          title: 'Nº de inversores',
          description:
            this.getModulesInvertersPropValues(kitInverters, 'amount') || '-',
        },
        {
          title: 'Marca inversor',
          description:
            this.getModulesInvertersPropValues(inverterAttr, 'brand') || '-',
        },
        {
          title: 'Modelo inversor',
          description:
            this.getModulesInvertersPropValues(inverterAttr, 'model') || '-',
        },
        {
          title: 'Potência dos inversores',
          description:
            `${this.getkitInvertersItemValues(kitInverters, 'power')} kW` ||
            '-',
        },
        {
          title: 'Nºs de mppts',
          description:
            this.getModulesInvertersPropValues(inverterAttr, 'mppts_number') ||
            '-',
        },
        {
          title: 'Tensão max de entrada',
          description: this.getModulesInvertersPropValues(
            inverterAttr,
            'voltage',
          )
            ? `${this.getModulesInvertersPropValues(inverterAttr, 'voltage')} V`
            : '-',
        },
        {
          title: 'Corrente max de entrada',
          description: this.getModulesInvertersPropValues(
            inverterAttr,
            'current',
          )
            ? `${this.getModulesInvertersPropValues(inverterAttr, 'current')} A`
            : '-',
        },
        {
          title: 'Tipo de ligação',
          description:
            this.getModulesInvertersPropValues(inverterAttr, 'connection') ||
            '-',
        },
        { title: 'Tipo de telhado', description: roofType || '-' },
        {
          title: 'Qtde de cabos',
          description: attributes.amount_cables || '-',
        },
        {
          title: 'Qtde de pares conectores',
          description: attributes.amount_connectors || '-',
        },
      ];
    },

    closeModal(val) {
      setTimeout(() => {
        this.$emit('input', val);
      }, 300);
    },

    selectProduct() {
      this.showModal = false;

      setTimeout(() => {
        this.$emit('selected', this.data);
        this.$emit('input', false);
      }, 300);
    },

    dataSheetSendTrack(event) {
      this.sendTracking('click_datasheet', 'ecommerce', event);
    },
  },
};
</script>

<style lang="scss">
.product-detail {
  .description {
    @apply rounded border border-solid border-gray2 p-4;

    h4 {
      @apply text-grayPrimary font-rubik text-small font-bold leading-tight mb-1;
    }

    ul {
      @apply p-4 pb-0;

      li {
        @apply text-grayPrimary text-small leading-normal list-disc;
      }
    }
  }

  .datasheet {
    @apply bg-gray2 p-4 rounded mt-2;

    p {
      @apply mb-4 text-slight;
    }

    .mail {
      @apply bg-grayPrimary rounded text-white font-bold text-small flex items-center justify-center h-9;
    }
  }

  footer {
    @apply relative justify-start flex-row #{!important};
  }

  .price {
    @apply font-rubik absolute text-modest leading-tight font-medium;
    right: 28px;

    span {
      @apply text-medium leading-none;
    }
  }
}
</style>
