<template>
  <div :id="id" class="file-preview">
    <template v-if="value.length">
      <transition-group name="thumb-list" class="thumb-list" appear>
        <div
          v-for="(file, index) in value"
          :key="`file-${index}`"
          class="thumb"
          :style="previewImg(file)"
        >
          <span class="remove" @click.stop.prevent="removeFile(file)">
            <Icon type="remove" class="ico-remove" />
            <span class="tooltip-remove">Excluir</span>
          </span>
        </div>
      </transition-group>
    </template>

    <p v-else>{{ placeholder }}</p>
  </div>
</template>

<script>
import Icon from './../Icon';

export default {
  name: 'FilePreview',

  components: {
    Icon,
  },

  props: {
    id: {
      type: [String, Number],
      default: null,
    },

    value: {
      type: Array,
      default: () => [],
      require: true,
    },

    placeholder: {
      type: String,
      default: 'Adicione um arquivo',
    },

    noImage: {
      type: String,
      default: () => require('@img/icons/icon-pdf.png?inline'),
    },
  },

  data: () => {
    return {
      previewFiles: [],
    };
  },

  methods: {
    removeFile(file) {
      this.previewFiles = this.value.filter((item) => item !== file);
      this.$emit('input', this.previewFiles);
    },

    previewImg(item) {
      return [
        {
          backgroundImage: `url(${URL.createObjectURL(item)})`,
          backgroundSize: 'cover',
        },
        { backgroundImage: `url(${this.noImage})` },
      ][Number(item.type === 'application/pdf')];
    },
  },
};
</script>

<style lang="scss" scoped>
.file-preview {
  @apply flex flex-wrap gap-2;

  .thumb-list {
    @apply flex flex-wrap gap-2;

    &-leave-to,
    &-enter-from {
      @apply opacity-0;
    }

    &-leave-active {
      position: absolute;
    }
  }

  .thumb {
    @apply bg-gray2 bg-no-repeat bg-center block w-11 h-11 rounded  duration-200 ease-in transition-all;
    background-size: 50%;

    @screen lg {
      @apply w-13 h-13;
      background-size: inherit;
    }

    .remove {
      @apply w-full h-full top-0 left-0 flex items-center justify-center duration-300 ease-in transition-all opacity-0 relative;
      background: rgba(#333, 0.2);

      .ico-remove {
        fill: #fff;
      }

      .tooltip-remove {
        @apply py-2 px-4 rounded bg-white text-gray4 font-rubik leading-tight font-normal absolute opacity-0 invisible duration-300 ease-in transition-all shadow-sm;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% + 10px);

        &::before {
          @apply border-solid absolute;
          border-width: 6px;
          content: '';
          right: 100%;
          top: 50%;
          transform: translateY(-50%);
          border-color: transparent #fff transparent transparent;
        }
      }
    }

    &:hover {
      .remove {
        @apply opacity-100 cursor-pointer;

        .tooltip-remove {
          @apply opacity-100 visible;
          left: calc(50% + 18px);
        }
      }
    }
  }
}
</style>
