<template>
  <div class="c-filters">
    <div class="c-filters__block">
      <div class="c-filters__block__head">
        <div class="c-filters__block__head__actions">
          <div class="c-filters__block__head__actions__order" v-if="isMobile">
            <DropdownOrder
              v-if="isMobile"
              :orderOptions="orderOptions"
              @onSelectChange="handleSort($event)"
            />
          </div>

          <Button
            id="show-filters"
            size="medium"
            variant="secondary"
            @click="toggleFiltersView()"
          >
            {{ filterButtonText }}
            <IconFilter />
            <span v-if="totalFiltersApplied">{{ totalFiltersApplied }}</span>
          </Button>
        </div>
      </div>

      <div
        id="filters-block-options"
        :class="[
          'c-filters__block__options',
          { 'show-mobile': isMobile && showMobileFilters },
          { 'show-more': showAllFilters },
        ]"
      >
        <div class="c-filters__block__options__head" v-if="isMobile">
          <Button
            id="close-filters"
            size="medium"
            variant="secondary"
            @click="toggleFiltersView()"
          >
            <IconBack />
          </Button>

          <div class="c-filters__block__options__head__title">
            <IconFilter />
            <span>Filtrar</span>
          </div>
        </div>

        <div
          v-for="(filter, i) in currentFilters"
          :key="`${filter.type}-${filter.field}`"
          class="c-filters__block__options__field"
        >
          <ToggleSwitch
            v-if="filter && filter.type === 'switch'"
            :id="`switch-${i}`"
            :label="filter.label"
            v-model="filterData[filter.field]"
            :class="[
              { 'filter-applied': isFilterFieldApplied(filter.field) },
              i ? 'unloading' : 'shipping',
            ]"
            :disabled="filter.disabled"
            @input="applyFiltersSwitch(filter, $event)"
          />

          <Tooltip
            v-if="filter && filter.field === 'kit_own_logistic'"
            :id="`kit_own_logistic-${i}`"
            position="bottomleft"
          >
            <IconQuestion class="icon-question" />
            <template slot="tooltip">
              <ShippingInfo />
            </template>
          </Tooltip>

          <div v-if="filter && filter.type === 'input'" class="div-input">
            <Input
              v-if="filter.field === 'kit_min_power'"
              :id="`input-${i}`"
              :class="[
                'input-power',
                { 'filter-applied': isFilterFieldApplied(filter.field) },
              ]"
              :invalid="$v.filterData.kit_min_power.$invalid"
              :msg-invalid="kitMinPowerValidationMessage"
              placeholder="Min. kWp"
              v-model="filterData[filter.field]"
              type="number"
              @blur="applyFilters(filter)"
            />
            <Input
              v-if="filter.field === 'kit_max_power'"
              :id="`input-${i}`"
              :class="[
                'input-power max_power',
                { 'filter-applied': isFilterFieldApplied(filter.field) },
              ]"
              :invalid="$v.filterData.kit_max_power.$invalid"
              :msg-invalid="kitMaxPowerValidationMessage"
              placeholder="Max. kWp"
              v-model="filterData[filter.field]"
              type="number"
              @blur="applyFilters(filter)"
            />
            <Input
              v-if="filter.field === 'code'"
              :id="`search-code`"
              :placeholder="filter.label"
              :class="[
                'search-input',
                { 'filter-applied': isFilterFieldApplied(filter.field) },
              ]"
              type="search"
              v-model="filterData[filter.field]"
              @eventHandler="applyFilters(filter)"
            />
          </div>

          <Multiselect
            v-if="filter && filter.type === 'multiselect'"
            :id="`multiselect-${i}`"
            :ref="`multiselect-${i}`"
            :options="getFilterValue(filter.filters)"
            :searchable="filter.searchable"
            :placeholder="filter.label"
            :hasGreenBackground="isFilterFieldApplied(filter.field)"
            v-model="filterData[filter.field]"
            hideChips
          >
            <template #buttons>
              <Button id="aplly" size="small" @click="applyFilters(filter)">
                Aplicar Filtros
              </Button>
              <Button
                id="clear"
                size="small"
                variant="secondary"
                @click="clearFilter(filter)"
              >
                Limpar
              </Button>
            </template>
          </Multiselect>

          <SelectInput
            v-if="filter && filter.type === 'selectinput'"
            :key="`select-input-${i}`"
            :id="`select-input-${i}`"
            :options="getFilterValue(filter.filters)"
            :searchable="filter.searchable"
            :placeholder="filter.label"
            :hasScroll="true"
            :class="{ 'filter-applied': isFilterFieldApplied(filter.field) }"
            v-model="filterData[filter.field]"
            @change="applyFilters(filter)"
          />
        </div>
      </div>
    </div>

    <div class="c-filters__chips" v-if="hasFilters">
      <template v-for="{ filters, key, tooltip } in chips">
        <Chip
          v-for="filterName in filters"
          :key="`chip-${filterName}`"
          :label="`${filterName}`"
          :tooltip="tooltip"
          @close="removeFilter(key, filterName)"
        />
      </template>

      <span class="clear-filters" @click="clearFilters()">Limpar Filtros</span>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import ShippingInfo from '@components/shared/ShippingInfo';
import DropdownOrder from '@components/shared/DropdownOrder';
import {
  Button,
  Chip,
  Input,
  Multiselect,
  SelectInput,
  ToggleSwitch,
  Tooltip,
} from '@solfacil/components-ui';
import IconFilter from '@img/icons/icon-filter.svg';
import IconBack from '@img/icons/icon-arrow-left.svg';
import IconQuestion from '@img/icons/icon-question.svg';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { helpers } from 'vuelidate/lib/validators';
import { ORDER_OPTIONS } from '@/helper/constants.helper';

const kitMinPower = (param) =>
  helpers.withParams({ type: 'kitMinPower' }, (value, vm) => {
    if (!Number(value)) return true;
    if (Number(value) < 0) return false;
    if (
      vm['kit_max_power'] &&
      Number(vm['kit_max_power']) < Number(param.max) &&
      Number(vm['kit_max_power']) >= 0
    )
      return Number(value) <= Number(param.max);

    return Number(value) >= 0 && Number(value) <= Number(param.max);
  });

const kitMaxPower = (param) =>
  helpers.withParams({ type: 'kitMaxPower' }, (value) => {
    if (!value) return true;
    if (Number(value) < 0) return false;

    return (
      Number(value) >= Number(param.min) && Number(value) <= Number(param.max)
    );
  });

export default {
  name: 'FilterOptions',

  components: {
    Button,
    Chip,
    DropdownOrder,
    IconQuestion,
    IconFilter,
    IconBack,
    Input,
    Multiselect,
    SelectInput,
    ShippingInfo,
    ToggleSwitch,
    Tooltip,
  },

  mixins: [validationMixin],

  props: {
    filters: {
      default: () => [{}],
      require: true,
      type: Array,
    },
  },

  data() {
    return {
      filterData: {},
      totalFilters: 4,
      showMobileFilters: false,
      mutationObserver: null,
      filtersBlockWidth: null,
      orderOptions: ORDER_OPTIONS,
      selectionMinPower: 0,
      selectionMaxPower: 0,
    };
  },

  computed: {
    ...mapGetters('marketplace', ['filtersSelected', 'newFiltersFlag']),

    showAllFilters() {
      return this.$store.state.marketplace.showMoreFilters;
    },

    selectionPowerRange() {
      const min =
        this.$store.state.marketplace?.newFilters?.find(
          (filter) => filter.field === 'kit_min_power',
        )?.filters[0]?.value / 1000;

      const max =
        this.$store.state.marketplace?.newFilters?.find(
          (filter) => filter.field === 'kit_max_power',
        )?.filters[0]?.value / 1000;

      return { min, max };
    },

    currentFilters() {
      return this.filters.filter((item) => item);
    },

    isMobile() {
      return window.screen.width <= 900;
    },

    filterButtonText() {
      if (this.isMobile) return 'Filtrar';
      return this.showAllFilters ? 'Menos filtros' : 'Mais filtros';
    },

    hasFilters() {
      return Boolean(Object.keys(this.filtersSelected).length);
    },

    chips() {
      const powerKeys = ['kit_min_power', 'kit_max_power'];
      const minPower = this.filterData['kit_min_power'] || 0;
      const maxPower =
        this.filterData['kit_max_power'] || this.selectionPowerRange.max;

      const tmpFiltersSelected = cloneDeep(this.filtersSelected);
      let filterKeys = Object.keys(tmpFiltersSelected);

      const chipsLabels = {
        delivering_deadline: {
          text: 'Entrega em até # dias úteis',
          values: [tmpFiltersSelected['delivering_deadline']],
        },
        kit_own_logistic: { text: 'Solfácil envios', values: [] },
        kit_unloading: { text: 'Descarregamento incluso', values: [] },
        kit_black_friday: { text: 'Solar Friday', values: [] },
        kit_insurance_installation: { text: 'Seguro instalação', values: [] },
      };

      for (const key of filterKeys) {
        if (Object.keys(chipsLabels).includes(key))
          tmpFiltersSelected[key] = this.parseChipsLabel(chipsLabels[key]);
      }

      if (filterKeys.some((key) => powerKeys.includes(key))) {
        delete tmpFiltersSelected['kit_min_power'];
        delete tmpFiltersSelected['kit_max_power'];

        tmpFiltersSelected['power'] = this.parseChipsLabel({
          text: '# - # kWp',
          values: [
            isNaN(minPower) ? 0 : minPower,
            isNaN(maxPower) ? 0 : maxPower,
          ],
        });

        filterKeys = Object.keys(tmpFiltersSelected);
      }

      return filterKeys.map((key) => {
        const filters = Array.isArray(tmpFiltersSelected[key])
          ? tmpFiltersSelected[key]
          : [String(tmpFiltersSelected[key])];

        const tooltip =
          this.currentFilters.find((item) => item.field === key)?.label || '';

        return {
          filters,
          key,
          tooltip,
        };
      });
    },

    totalFiltersApplied() {
      return Object.keys(this.filtersSelected).length;
    },

    formattedFilters() {
      return Object.fromEntries(
        Object.entries(this.filterData).filter(([, value]) => {
          if (Array.isArray(value)) return Boolean(value.length);
          return Boolean(value);
        }),
      );
    },

    kitMinPowerValidationMessage() {
      const min =
        this.filterData?.kit_min_power &&
        Number(this.filterData?.kit_min_power) <= this.selectionPowerRange.max
          ? Number(this.filterData?.kit_min_power) >= 0
            ? this.filterData?.kit_min_power
            : 0
          : 0;

      const max =
        this.filterData?.kit_max_power &&
        Number(this.filterData?.kit_max_power) <= this.selectionPowerRange.max
          ? this.filterData?.kit_max_power
          : this.selectionPowerRange.max;

      if (this.$v.filterData.kit_min_power.$invalid) {
        if (!this.$v.filterData.kit_min_power.kitMinPower)
          return `Entre ${min} e ${max}`;
      }

      return '';
    },

    kitMaxPowerValidationMessage() {
      const min =
        this.filterData?.kit_min_power &&
        Number(this.filterData?.kit_min_power) <= this.selectionPowerRange.max
          ? Number(this.filterData?.kit_min_power) >= 0
            ? this.filterData?.kit_min_power
            : 0
          : 0;

      const max =
        this.filterData?.kit_max_power &&
        Number(this.filterData?.kit_max_power) <= this.selectionPowerRange.max
          ? this.filterData?.kit_max_power >= 0
            ? this.filterData?.kit_max_power
            : 0
          : this.selectionPowerRange.max;

      if (this.$v.filterData.kit_max_power.$invalid) {
        if (!this.$v.filterData.kit_max_power.kitMaxPower)
          return `Entre ${min} e ${max}`;
      }

      return '';
    },
  },

  beforeMount() {
    if (this.isMobile) this.totalFilters = 999;
    if (this.filtersSelected) this.filterData = cloneDeep(this.filtersSelected);
  },

  mounted() {
    this.filtersBlockWidth = document
      .getElementById('filters-block-options')
      .getBoundingClientRect().width;

    if (!this.isMobile) {
      if (this.showAllFilters) this.handleShowAllFilters();
      else this.handleShowLessFilters(this.filtersBlockWidth);
    }

    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.removedNodes.length)
          this.applyFiltersByBlur();
      });
    });

    const multiselects = [];
    for (const [key, value] of Object.entries(this.$refs))
      if (key.includes('multiselect')) multiselects.push(value[0].$el);

    for (const ms of multiselects)
      this.mutationObserver.observe(ms, {
        childList: true,
      });
  },

  watch: {
    selectionPowerRange({ min, max }) {
      const { kit_min_power, kit_max_power } = this.filterData;

      if (kit_min_power && min !== Number(kit_min_power))
        this.filterData.kit_min_power = min;

      if (kit_max_power && min !== Number(kit_max_power))
        this.filterData.kit_max_power = max;
    },
  },

  methods: {
    ...mapActions('marketplace', [
      'setSort',
      'getProducts',
      'getNewFilters',
      'setFiltersSelected',
      'toggleMoreFiltersView',
    ]),

    parseChipsLabel({ text, values }) {
      if (!values.length) return text;
      values.forEach((value) => (text = text.replace('#', value)));
      return text;
    },

    async handleSort(order) {
      if (!order) return;

      const { key, sort } = order[0];

      await this.setSort({ sortBy: key, sortOrder: sort });
      this.getProducts();

      this.sendTracking(`order_by_${key}`, 'ecommerce', {
        sort_order: sort,
        flag: this.newFiltersFlag,
      });
    },

    toggleFiltersView() {
      if (this.isMobile) {
        this.showMobileFilters = !this.showMobileFilters;
        document.body.style.overflow = this.showMobileFilters
          ? 'hidden'
          : 'auto';
      }

      if (!this.isMobile) {
        const minFilter = 4;

        this.toggleMoreFiltersView();

        if (this.showAllFilters) this.handleShowAllFilters();
        else this.handleShowLessFilters();

        this.totalFilters =
          this.totalFilters === minFilter ? this.filters.length - 1 : minFilter;
      }

      this.sendTracking('click_show_filters', 'ecommerce', {
        show: this.showAllFilters ? 'more' : 'less',
        flag: this.newFiltersFlag,
      });
    },

    handleShowAllFilters() {
      const filters = document.getElementById('filters-block-options').children;
      for (const filter of filters) filter.style.display = 'flex';
    },

    handleShowLessFilters(
      blockWidth = this.filtersBlockWidth,
      oldBlockWidth = 0,
    ) {
      const filters = document.getElementById('filters-block-options').children;
      let filtersWidthSum = 0;

      for (const filter of filters) {
        if (blockWidth > oldBlockWidth) filter.style.display = 'flex';
        if (filtersWidthSum < blockWidth)
          filtersWidthSum += filter.offsetWidth + 12;
        if (filtersWidthSum > blockWidth) filter.style.display = 'none';
      }
    },

    setFilters() {
      this.setFiltersSelected(this.formattedFilters);
      this.getNewFilters();
      this.getProducts();
    },

    applyFiltersSwitch(filter, isActive) {
      this.filterData[filter.field] = isActive;

      this.sendTracking('apply_filter', 'ecommerce', {
        [filter.originalField]: this.filterData[filter.field],
        flag: this.newFiltersFlag,
      });
      this.setFilters();
    },

    applyFilters(filter) {
      if (this.$v.$invalid) return;

      this.sendTracking('apply_filter', 'ecommerce', {
        [filter.originalField]: this.filterData[filter.field],
        flag: this.newFiltersFlag,
      });
      this.setFilters();
    },

    applyFiltersByBlur() {
      if (isEqual(this.filterData, this.filtersSelected)) return;

      this.sendTracking('apply_filter', 'ecommerce', {
        filteredByBlur: true,
        flag: this.newFiltersFlag,
      });
      this.setFilters();
    },

    getFilterValue(filters) {
      return filters.map((filter) => {
        if (filter.name !== 'Todos' && filter.amount) {
          return {
            name: `${filter.name} (${filter.amount} ${
              filter.amount === 1 ? 'kit' : 'kits'
            })`,
            value: filter.value,
          };
        } else return { name: `${filter.name}`, value: filter.value };
      });
    },

    removeFilter(key, value) {
      const singleFilters = [
        'delivering_deadline',
        'kit_own_logistic',
        'kit_unloading',
        'kit_insurance_installation',
        'kit_black_friday',
      ];

      if (key === 'power') {
        delete this.filterData['kit_min_power'];
        delete this.filterData['kit_max_power'];
      }

      if (singleFilters.includes(key)) {
        delete this.filterData[key];
      }

      if (Array.isArray(this.filterData[key])) {
        this.filterData[key].splice(this.filterData[key].indexOf(value), 1);
      }

      this.sendTracking('remove_filter', 'ecommerce', {
        filter: key,
        value: value,
      });
      this.setFilters();
    },

    clearFilter(filter) {
      this.sendTracking('clear_filter', 'ecommerce', {
        filter: filter.field,
        flag: this.newFiltersFlag,
      });
      delete this.filterData[filter.field];
      this.setFilters();
    },

    clearFilters() {
      this.filterData = {};
      this.sendTracking('clear_filters', 'ecommerce', {
        flag: this.newFiltersFlag,
      });
      this.setFilters();
    },

    isFilterFieldApplied(filter) {
      return Object.keys(this.filtersSelected).includes(filter);
    },
  },

  validations() {
    return {
      filterData: {
        kit_min_power: {
          kitMinPower: kitMinPower(this.selectionPowerRange),
        },
        kit_max_power: {
          kitMaxPower: kitMaxPower(this.selectionPowerRange),
        },
      },
    };
  },
};
</script>

<style lang="scss">
.c-filters {
  margin: 0 -16px;

  &__block {
    @screen lg {
      @apply bg-gray1 flex flex-row mt-6 mx-4 relative p-5;
      border-radius: 8px;
    }

    &__head {
      @apply py-3 px-4 bg-white border-b border-t border-gray3;

      @screen lg {
        @apply bg-transparent border-none p-0 flex-shrink order-2;
      }

      &__actions {
        @apply flex justify-between items-center;

        #show-filters {
          @apply capitalize relative;

          @screen lg {
            @apply p-0;
            width: 140px;
          }

          svg {
            @apply ml-3;
          }

          span {
            @apply w-6 h-6 rounded-full text-center border border-solid bg-rebrandingPrimary font-rubik text-slight font-normal text-white absolute;
            line-height: 23px;
            right: -12px;
            top: -12px;
          }
        }
      }
    }

    &__options {
      @apply bg-white absolute w-screen h-screen max-h-screen top-0 left-0 overflow-y-auto overflow-x-hidden hidden;
      z-index: 1000;

      @screen lg {
        @apply relative h-auto z-10 bg-transparent flex gap-3 overflow-visible items-center flex-wrap;
        // @apply relative h-auto z-10 bg-transparent flex gap-x-3 gap-y-4 overflow-hidden items-center flex-wrap;
        // max-height: 58px;

        // &.show-more {
        //   @apply max-h-full;
        // }
      }

      &.show-mobile {
        @apply block;
      }

      &__head {
        @apply py-4 px-4;

        button {
          @apply border-none;
          margin-left: -20px;

          svg {
            path {
              @apply fill-current text-rebrandingPrimary;
            }
          }
        }

        &__title {
          @apply flex gap-2 items-center text-large;
        }
      }

      &__field {
        @apply w-full items-center;

        @screen lg {
          @apply w-auto;
        }

        > * {
          @apply w-full;

          @screen lg {
            @apply w-auto relative;
          }

          .msg-error {
            position: absolute;
          }
        }

        @screen lg {
          .sol-select-input,
          .sol-multi-select-input {
            > label {
              color: #626262;
              font-weight: 700;
              font-family: inherit;
            }
            .select-main {
              min-width: 200px;
            }
            .options-container {
              min-width: 280px;
            }
          }

          .input-power {
            width: 110px;
          }

          .search-input {
            width: 200px;
          }

          .toggle-switch {
            @apply mr-3;

            &.shipping {
              background: url(~@/assets/img/solfacil-envios.png) no-repeat
                center;
              background-size: 95px;
              background-position-x: right;
              width: 140px;
              span span {
                @apply block h-3 overflow-hidden;
                text-indent: -999em;
              }
            }
          }
        }
      }
    }
  }

  &__chips {
    @apply py-3 px-4 flex items-center flex-wrap gap-3;

    .chip {
      @apply bg-transparent border border-gray4 py-2 px-2;
    }

    .clear-filters {
      @apply bg-transparent underline cursor-pointer;
    }
  }

  .filter-applied {
    .select-main,
    input {
      &:not(:hover) {
        @apply bg-rebrandingPrimary text-white;

        span,
        span.placeholder,
        svg {
          @apply text-white;

          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.selected-filters {
  @apply flex flex-wrap mt-4;

  .chip-filter {
    @apply mr-4 mb-4;
  }

  .clear-filters {
    @apply underline cursor-pointer mt-2;
    &:hover {
      @apply text-green3;
    }
  }
}
</style>
