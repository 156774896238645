<template>
  <Modal
    id="modal-details-status-order"
    title="Avaliar Entrega"
    :value="isOpenModal"
    :button-close="orderRated ? 'primary' : null"
    @closeModal="$emit('closeModal')"
  >
    <div v-if="!orderRated">
      <div class="flex">
        <h2 class="mt-1 mr-2">
          Como você avalia sua experiência com a entrega?
        </h2>
        <RatingStars v-model="rating"></RatingStars>
      </div>

      <Textarea
        id="textarea"
        placeholder="Escreva aqui"
        label="Deixe seu comentário (Opcional)"
        :rows="3"
        v-model="observation"
      />
    </div>
    <div v-else class="flex gap-4">
      <Icon type="success-order" />
      <div class="ml-3">
        <h2>Obrigado pelo seu Feedback!</h2>
        Sua opinião é muito importante para que nós possamos melhorar a
        plataforma de forma constante.
      </div>
    </div>
    <template #footer>
      <Button
        v-if="!orderRated"
        id="rating"
        :disabled="!rating"
        @click="sendEvaluate"
      >
        Avaliar
      </Button>
    </template>
  </Modal>
</template>
<script>
import { mapActions } from 'vuex';
import { Modal, Textarea } from '@solfacil/components-ui';
import { Icon, RatingStars } from '@components/';

export default {
  name: 'ModalEvaluateDelivery',
  components: {
    Icon,
    Modal,
    Textarea,
    RatingStars,
  },
  props: {
    isOpenModal: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isRating: false,
      rating: null,
      observation: '',
    };
  },

  computed: {
    orderRated() {
      return this.order.rating !== null || this.isRating;
    },
  },

  methods: {
    ...mapActions('order', ['createOrderRating']),

    async sendEvaluate() {
      const payload = {
        rating: this.rating,
        observation: this.observation,
        id: this.order.id,
      };

      this.isRating = await this.createOrderRating(payload);
    },

    resetFields() {
      this.rating = 0;
      this.observation = '';
      this.isRating = true;
    },
  },
};
</script>
<style scoped>
h2 {
  @apply font-rubik font-medium text-modest leading-tight mb-4;
}
</style>
