<template v-if="kitValidate">
  <section class="ct-datasheet-btn">
    <div v-for="inverter in kit.inverterAttr" :key="inverter.model">
      <div
        :class="datasheetClassBtn(inverter.datasheet)"
        :key="inverter.model"
        target="_blank"
        @click="handleClick({ label: 'inversor', ...inverter })"
      >
        <strong>
          DATASHEET DO INVERSOR {{ inverter.brand.toUpperCase() }}
          {{ inverter.model.toUpperCase() }}
        </strong>
      </div>
    </div>

    <div v-for="modules in kit.modulesAttr" :key="modules.model">
      <div
        :class="datasheetClassBtn(modules.datasheet)"
        @click="handleClick({ label: 'modulo', ...modules })"
      >
        <strong>
          DATASHEET DO MODULO {{ modules.brand.toUpperCase() }}
          {{ modules.model.toUpperCase() }}
        </strong>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DatasheetButtons',
  props: {
    kit: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    kitValidate() {
      return Object.keys(this.kit).length;
    },
  },
  methods: {
    handleClick(event) {
      if (this.kitValidate) {
        window.open(event.datasheet, '_blank');
        this.$emit('onSendTrack', event);
      }
    },
    datasheetClassBtn(datasheet) {
      return datasheet ? 'datasheet__btn' : 'datasheet__btn disabledbutton';
    },
  },
};
</script>

<style lang="scss" scoped>
.ct-datasheet-btn {
  @apply flex flex-col py-4 px-5 rounded items-center justify-center gap-4;
  border: 1px solid rgba(200, 200, 200, 0.64);

  @screen md {
    @apply flex-row flex-wrap;
  }
  .datasheet__btn {
    border: 1px solid #1aa66b;
    @apply flex rounded p-2 cursor-pointer;

    @screen md {
      @apply flex-row;
    }

    strong {
      @apply text-center;
    }

    &.disabledbutton {
      border: 1px solid rgb(200, 200, 200);
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
</style>
