export default [
  {
    path: '/',
    redirect: { name: 'home' },
    component: () => import('@/layouts/Default'),
    children: [
      {
        path: '/marketplace',
        redirect: '/loja',
      },
      {
        path: '/loja',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
          title: 'Comprar',
        },
      },
    ],
  },
];
