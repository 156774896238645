<template>
  <div class="sol-step">
    <ul>
      <li
        v-for="(step, index) in cloneSteps"
        :key="index"
        :class="{ active: step.active, current: index === stepIndex }"
        @click="handleSteps(index, step)"
      >
        {{ step.label }}
      </li>
    </ul>

    <hr class="mt-6 mb-4" />

    <transition name="fade" mode="out-in">
      <component :is="selectComponent" ref="step" />
    </transition>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'MainSteps',

  props: {
    steps: {
      type: Array,
      require: true,
      default: () => [],
      validator: (obj) => {
        if (!(obj && obj.constructor === Array)) return false;
        if (obj.length === 0) return true;
        const ListProperties = ['label', 'component', 'active'].filter(
          (property) => !Object.prototype.hasOwnProperty.call(obj[0], property),
        );
        return ListProperties.length === 0;
      },
    },
  },

  data: () => {
    return {
      cloneSteps: [],
      stepIndex: 0,
      total: null,
      selectComponent: null,
    };
  },

  created() {
    this.cloneSteps = cloneDeep(this.steps);
    this.total = this.cloneSteps.length;
    this.setStepComponent();
  },

  methods: {
    setStepComponent() {
      this.selectComponent = () =>
        import(`@/components${this.cloneSteps[this.stepIndex].component}`);

      const checkoutStep = this.cloneSteps[this.stepIndex].query.checkout;

      this.$route.query.checkout !== checkoutStep &&
        this.$router.push({ name: 'cart', query: { checkout: checkoutStep } });
    },

    handleSteps(index, step) {
      if (index === this.stepIndex || this.stepIndex === 0 || !step.active)
        return;

      this.navStep('prev', index);
    },

    validStep() {
      if (this.cloneSteps[this.stepIndex].required) {
        return this.$refs.step?.check();
      }

      return true;
    },

    navStep(action, step = null) {
      if (action === 'next' && !this.validStep()) return;

      [
        () => {
          if (step !== null) {
            this.cloneSteps.map((item, i) => {
              item.active = step >= i ? true : false;
            });

            this.stepIndex = step;
            return;
          }

          this.cloneSteps[this.stepIndex].active = false;
          this.stepIndex > 0 && this.stepIndex--;
        },
        () => {
          this.stepIndex < this.total - 1 && this.stepIndex++;
          this.cloneSteps[this.stepIndex].active = true;
        },
      ][Number(action === 'next')]();

      this.$emit('input', this.stepIndex);

      this.setStepComponent();
    },
  },
};
</script>

<style lang="scss" scoped>
.sol-step {
  ul {
    @apply flex flex-col;
    counter-reset: section;

    li {
      @apply flex flex-1 items-center justify-start mb-4 text-gray4 text-medium leading-tight font-rubik font-normal duration-300 ease-in-out transition-all;

      &::before {
        @apply bg-white mr-2 flex items-center justify-center rounded-full text-center text-medium border-2 border-solid border-gray4 duration-300 ease-in-out transition-all;
        min-height: 32px;
        min-width: 32px;
        counter-increment: section;
        content: counter(section);
      }

      &.active {
        @apply text-grayPrimary cursor-pointer;

        &.current {
          @apply cursor-default;
        }

        &::before {
          @apply bg-rebrandingPrimary border-rebrandingPrimary text-white;
        }
      }
    }

    @screen md {
      @apply flex-row;
    }
  }
}
</style>
