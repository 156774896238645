<template>
  <Modal
    v-if="isOpen"
    id="modal-update-address"
    :value="isOpen"
    size-lg
    :self-close="!hasError"
    :disable-close="hasError"
    no-scroll
    title="Melhores ofertas e prazos"
    @closeModal="$emit('closeModal')"
  >
    <div class="img-cart">
      <img
        src="https://cdn.solfacil.com.br/frontoffice-loja/icons/person-checking-cart.svg"
        alt="Imagem de um carrinho de compras"
      />
    </div>

    <p class="update-address-message">
      Através da localização do projeto que você está cotando e/ou orçando,
      conseguimos oferecer as melhores ofertas e prazos.
    </p>

    <div class="flex flex-wrap lg:flex-no-wrap gap-4 mt-6">
      <SelectInput
        id="state"
        v-model="form.address.state"
        class="w-full lg:w-6/12"
        searchable
        placeholder="Selecione"
        label="Estado"
        msg-invalid="Campo obrigatório"
        :options="states"
        :invalid="$v.form.address.state.$error"
      />

      <SelectInput
        id="city"
        v-model="form.address.city"
        searchable
        placeholder="Selecione"
        label="Cidade"
        msg-invalid="Campo obrigatório"
        class="w-full lg:w-6/12"
        :options="cities"
        :invalid="$v.form.address.city.$error"
      />
    </div>

    <template #footer>
      <Button id="show-offer" @click="showOffer"> Ver ofertas </Button>
      <Button
        id="go-back"
        variant="secondary"
        :disabled="isDisabled"
        @click="$emit('closeModal')"
      >
        Voltar
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal, SelectInput } from '@solfacil/components-ui';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import { STATES } from '@/helper/constants.helper';
import cities from '@/mixins/cities.mixin';

export default {
  name: 'ModalUpdateAddress',
  components: {
    SelectInput,
    Button,
    Modal,
  },
  mixins: [validationMixin, cities],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      cities: [{ value: '', name: '' }],
      states: STATES,
      form: {
        address: { ...this.address },
      },
    };
  },

  validations() {
    return {
      form: {
        address: {
          city: {
            required,
          },
          state: {
            required,
          },
        },
      },
    };
  },

  computed: {
    isDisabled() {
      return (
        this.hasError || !Object.values(this.address).every((value) => value)
      );
    },

    hasError() {
      return (
        this.$v.$error ||
        Object.values(this.form.address).some((value) => value === '')
      );
    },
  },

  watch: {
    'form.address.state'(value) {
      if (value) this.form.address.city = '';
    },
  },

  methods: {
    showOffer() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit('closeModal', { ...this.form.address });
    },
  },
};
</script>
<style lang="scss" scoped>
.img-cart {
  img {
    @apply m-auto;
  }
}
.update-address-message {
  @apply text-modest font-normal text-center mt-3;
  line-height: 150%;
}

#show-offer {
  @apply mr-4;
}
</style>
