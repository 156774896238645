export default [
  {
    path: '/',
    redirect: { name: 'home' },
    component: () => import('@/layouts/Default'),
    children: [
      {
        path: '/loja/pedido',
        redirect: { name: 'storeOrderList' },
      },
      {
        path: '/loja/meus-pedidos',
        name: 'storeOrderList',
        component: () => import('@/views/Orders/OrderList.vue'),
        meta: {
          title: 'Meus pedidos',
          partnerOnly: true,
        },
      },
    ],
  },

  {
    path: '/',
    redirect: { name: 'home' },
    component: () => import('@/views/Orders/OrderDetail.vue'),
    children: [
      {
        path: '/loja/pedido/:code',
        name: 'storeOrderDetail',
        props: true,
        meta: {
          title: 'Detalhes do Pedido',
          partnerOnly: true,
        },
      },
    ],
  },
];
