import { render, staticRenderFns } from "./MarketplacePopOver.vue?vue&type=template&id=7fbb6fbf&scoped=true&"
import script from "./MarketplacePopOver.vue?vue&type=script&lang=js&"
export * from "./MarketplacePopOver.vue?vue&type=script&lang=js&"
import style0 from "./MarketplacePopOver.vue?vue&type=style&index=0&id=7fbb6fbf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fbb6fbf",
  null
  
)

export default component.exports