import * as LDClient from 'launchdarkly-js-client-sdk';

import { TokenService } from '@/services';

export default {
  install(Vue) {
    Vue.prototype.$launchdarkly = (function () {
      let singleInstance;

      function initialize() {
        const { name, email, external_id: key } = TokenService.getUser() || {};

        const user = {
          key: key || '',
          name: name || '',
          email: email || '',
        };

        return LDClient.initialize(Vue.prototype.$launchdarklyId, user, {
          bootstrap: {},
        });
      }

      return {
        instance: () => {
          if (!singleInstance) {
            singleInstance = initialize();
          }

          return singleInstance;
        },
      };
    })();
  },
};
