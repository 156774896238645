import Vue from 'vue';
import VueScreen from 'vue-screen';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { Directives, Filters, Toaster } from '@solfacil/components-ui';
import { ApiService, TokenService } from '@/services';
import { environments } from '@/utils';
import UsersService from './services/user';
import keycloak from '@/plugins/keycloak';
import mockedLogin from '@/data/mockedLogin.json';

import Headline from '@components/shared/Headline';

import '@/plugins';
import '@/filters';

// Set environment
environments();

import './assets/styles/index.scss';

Vue.use(Filters);
Vue.use(Directives);
Vue.use(Toaster, { timeout: 5000, id: 'toaster' });
Vue.use(VueScreen, {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1366px',
});

Vue.component('HeadlinePage', Headline);

// Set the base URL of the API
ApiService.init(Vue.prototype.$api.apiURL);

// If token exists set header
if (TokenService.getToken()) {
  applyAuthenticationHeaders();
}

export const executeLogout = () => {
  TokenService.removeStorage();
  ApiService.removeHeader();
  Vue.$keycloak.logout();
};

const saveToken = async (token) => {
  TokenService.saveToken(token);
};

function applyAuthenticationHeaders() {
  ApiService.setHeader();
}

const getUser = async (authenticating) => {
  const user = await UsersService.getMe();
  const userData = await convertUserToString(user);
  TokenService.saveUser(userData);
  return store.dispatch('auth/setUser', { user, authenticating });
};

const convertUserToString = (user) => {
  return Promise.resolve(
    JSON.stringify({
      name: user?.name || '',
      email: user?.email || '',
      id: user?.id || '',
      external_id: user?.external_id || '',
      phone: user?.phone_number || '',
      company_name: user?.customer?.company_name || '',
      state: user.customer?.address?.state || '',
      city: user.customer?.address?.city || '',
      profile: user?.profile || '',
      level: user?.customer?.level || '',
      cnpj: user?.customer?.partner?.cnpj || '',
    }),
  );
};

let app = null;

const initApp = () => {
  app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

if (process.env.VUE_APP_MOCKED_USER) {
  saveToken(mockedLogin[process.env.VUE_APP_MOCKED_USER]);
  applyAuthenticationHeaders();
  getUser(true).then(() => initApp());
} else {
  Vue.use(keycloak);
  Vue.$keycloak
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    })
    .then(async (authenticating) => {
      saveToken(Vue.$keycloak.token);
      applyAuthenticationHeaders();
      await getUser(authenticating);
      initApp();
    });

  Vue.$keycloak.onAuthSuccess = () => {
    setInterval(
      () =>
        Vue.$keycloak
          .updateToken(60)
          .then((refreshed) => {
            if (refreshed) {
              saveToken(Vue.$keycloak.token);
              applyAuthenticationHeaders();
            }
          })
          .catch(() => Vue.$keycloak.clearToken()),
      30000,
    );
  };
}

export { app };
