export function getFormattedProductsPrice(products) {
  return products.reduce((acc, item) => {
    if (hasProperties(item.node.shipping)) {
      if (item.node.discounted_price) {
        item.node.price += item.node.shipping.cost;
        item.node.discounted_price += item.node.shipping.cost;
        return [...acc, item];
      }

      item.node.price += item.node.shipping.cost;
      return [...acc, item];
    }
    return products;
  }, []);
}

export function getFormattedProductsPriceInOrder(orders) {
  return orders.reduce((acc, item) => {
    const hasShipping = item.details[0].products.some((value) =>
      hasProperties(value.shipping),
    );

    if (hasShipping) {
      item.details[0].products = _addFreightProducts(item.details[0].products);
      return [...acc, item];
    }

    return orders;
  }, []);
}
export function getFormattedOneProductPriceInOrder(order) {
  return order.reduce((acc, item) => {
    if (hasProperties(item?.shipping)) {
      if (item.discounted_price) {
        item.price += item.shipping.cost;
        item.discounted_price += item.shipping.cost;
        return [...acc, item];
      }

      item.price += item.shipping.cost;
      return [...acc, item];
    }
    return order;
  }, []);
}

function hasProperties(value) {
  return value && Object.values(value).length;
}

function _addFreightProducts(products) {
  return products.reduce((acc, item) => {
    if (item.discounted_price) {
      item.discounted_price += item.shipping.cost;
      item.price += item.shipping.cost;
      return [...acc, item];
    }

    item.price += item.shipping.cost;
    return [...acc, item];
  }, []);
}
