export default {
  data: () => ({
    dictionaryStatus: {
      created: {
        fill: '#7DD0FF',
        variant: 'in-progress',
        label: 'Pedido recebido',
      },
      confirmed: {
        fill: '#7DD0FF',
        variant: 'in-progress',
        label: 'Pedido confirmado',
      },
      waiting_payment: {
        fill: '#7DD0FF',
        variant: 'in-progress',
        label: 'Aguardando pagamento',
      },
      paid: {
        fill: '#7DD0FF',
        variant: 'in-progress',
        label: 'Pedido pago',
      },
      issued_nf: {
        fill: '#7DD0FF',
        variant: 'in-progress',
        label: 'NF Emitida',
      },
      delivering: {
        fill: '#7DD0FF',
        variant: 'in-progress',
        label: 'Em trânsito',
      },
      delivered: {
        fill: '#4CD89D',
        variant: 'done',
        label: 'Entregue',
      },
      canceled: {
        fill: '#FF7771',
        variant: 'removed',
        label: 'Cancelado',
      },
      after_sales: {
        fill: '#FFB600',
        variant: 'review',
        label: 'Pós-venda',
      },
      devolution: {
        fill: '#FFB600',
        variant: 'review',
        label: 'Devolução',
      },
      chargeback: {
        fill: '#FFB600',
        variant: 'review',
        label: 'Estorno',
      },
    },
  }),
};
