import { DEFAULT_API } from '@utils';

export default {
  methods: {
    isProduction() {
      return process.env.NODE_ENV === 'production';
    },

    getEnviromnentParam() {
      const urlParams = new URLSearchParams(
        decodeURIComponent(window.location.search),
      );
      return urlParams.get('env')
        ? urlParams.get('env')
        : localStorage.getItem('apiServer');
    },
    getUrlSolfacil() {
      const env = this.getEnviromnentParam();
      if (env && DEFAULT_API[env]) return DEFAULT_API[env].legacyURL;
      return DEFAULT_API[process.env.NODE_ENV].legacyURL;
    },
    getUrlSolfacilMais() {
      if (this.isProduction()) return 'https://solfacilmais.solfacil.com.br/';
      return 'https://solfacilmais.pages.dev/';
    },
    getUrlMonitoring() {
      if (this.isProduction()) return 'https://monitoramento.solfacil.com.br';
      return 'https://pro-frontend.pages.dev/';
    },
  },
};
