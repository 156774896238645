import orderRoutes from './orders';
const requireRoute = require.context('.', false, /\.js$/); // Get js files inside routes folder
const routes = [];

const flags = JSON.parse(localStorage.getItem('flags'));
const redirect_SI9 = flags?.redirect_SI9?.current ?? false;

if (redirect_SI9) {
  routes.push(...orderRoutes);
  routes.push({
    path: '*',
    component: () => import('@/views/RedirectSolarInove.vue'),
  });
} else {
  requireRoute.keys().forEach((fileName) => {
    if (fileName === './index.js') {
      return;
    }
    const route = requireRoute(fileName).default;
    routes.push(...route);
  });

  routes.push({
    path: '/',
    component: () => import('@/layouts/Default'),
    children: [
      {
        path: '*',
        component: () => import('@/views/NotFound.vue'),
        meta: {
          title: 'Página não encontrada',
          rule: '*',
        },
      },
    ],
  });
}

export default routes;
