import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import modules from './modules';

Vue.use(Vuex);

const dataState = createPersistedState({
  key: 'mkt',
  paths: ['marketplace'],
});

export default new Vuex.Store({
  modules: {
    ...modules,
  },
  plugins: [dataState],
  strict: process.env.NODE_ENV === 'development',
});
