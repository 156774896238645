import { ApiService, ResultError } from '@/services';

const DataLoader = {
  /**
   * New register
   *
   * @returns status
   * @throws ResultError
   **/
  post: async function (data, isLoading = true) {
    try {
      const response = await ApiService.post(data, isLoading);

      return response.data;
    } catch (error) {
      ResultError(error);
    }
  },

  postMkt: async function (resource, data, isLoading = true) {
    try {
      return await ApiService.postMkt(resource, data, isLoading);
    } catch (error) {
      return { error };
    }
  },
};

export default DataLoader;

export { DataLoader, ResultError };
