<template>
  <Component :is="dynamicIcon" @click="click" />
</template>

<script>
export default {
  name: 'IconSvg',

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    dynamicIcon() {
      return () => import(`@img/icons/icon-${this.type}.svg`);
    },
  },

  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
